import {connect} from "react-redux";
import React from "react";
import {changeRecipeName} from "./actions";
import * as LABELS from "./labels";
import {Dispatch} from "redux";

interface IRecipeNameProps {
    name: string;
    changeRecipeNameProp: (name: string) => void;
    showError: () => boolean;
}

const RecipeName = ({name, changeRecipeNameProp, showError}: IRecipeNameProps) => (
    <div style={{width: "100%"}}>
        <input
            className={`change-recipe-name-input ${
                showError() && name === "" ? "inputError" : "inputOK"
            }`}
            onChange={event => changeRecipeNameProp(event.target.value)}
            placeholder={LABELS.PLACEHOLDER_RECIPE_NAME}
            value={name}
        />
    </div>
);

const mapStateToProps = (state: any) => ({
    name: state.changeRecipeState.name,
    showError: () =>
        state.changeRecipeState.stateStatus === LABELS.RECIPE_SAVE_FAILED
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    changeRecipeNameProp: (name: string) => dispatch(changeRecipeName(name))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RecipeName);
