import React, {Component} from "react";
import {connect} from "react-redux";
import SearchField from "./searchField";
import RecipeSearchResult from "./recipeSearchResult";
import RecipeCategoryList from "./recipeCategoryList";
import {loadRecipes} from "./actions";
import {IRecipeCollectionDto} from "../showRecipe/types";

const RecipeApi = require("../api/recipeApi");

interface ISearchRecipeProps {
    dispatch: any;
}

class SearchRecipe extends Component<ISearchRecipeProps> {
    componentDidMount() {
        RecipeApi.getAllRecipes().then((recipeCollectionDto: IRecipeCollectionDto) => {
            this.props.dispatch(loadRecipes(recipeCollectionDto.recipes));
        });
    }

    render() {
        return (
            <div className="SearchRecipe">
                <h1>Dine oppskrifter</h1>
                <div className="SearchRecipe-SearchField">
                    <RecipeCategoryList/>
                    <SearchField/>
                </div>
                <div className="SearchRecipe-RecipeSearchResult">
                    <RecipeSearchResult/>
                </div>
            </div>
        );
    }
}

export default connect()(SearchRecipe);
