import * as HttpStatus from "http-status-codes";
import {faCheckCircle, faSave, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {RECIPE_CHANGES_PENDING, RECIPE_SAVE_FAILED, RECIPE_SAVED_OK} from "./labels";
import {BUTTONSTATE_ERROR, BUTTONSTATE_OK, BUTTONSTATE_PENDING} from "../components/styledButton";
import {IRecipe} from "../showRecipe/types";

const RecipeApi = require("../api/recipeApi");

const stripTrailingSlash = (str: string) => (str.endsWith("/") ? str.slice(0, -1) : str);

export const GetButtonState = (currentState: string) => {
    switch (currentState) {
        case RECIPE_SAVED_OK:
            return BUTTONSTATE_OK;
        case RECIPE_SAVE_FAILED:
            return BUTTONSTATE_ERROR;
        case RECIPE_CHANGES_PENDING:
            return BUTTONSTATE_PENDING;
        default:
            return "";
    }
};

export const GetButtonIcon = (currentState: string) => {
    switch (currentState) {
        case RECIPE_SAVED_OK:
            return faCheckCircle;
        case RECIPE_SAVE_FAILED:
            return faTimesCircle;
        case RECIPE_CHANGES_PENDING:
            return faSave;
        default:
            return faSave;
    }
};

export const HandleLoadRecipe = (location: Location, onLoad: (response: any) => void, onClear: () => void) => {
    if (location.pathname.startsWith("/oppskrifter")) {
        const argument = stripTrailingSlash(location.pathname)
            .split("/")
            .pop();
        if (argument === "endre") {
            onClear();
        } else if (argument !== null && argument !== "oppskrifter") {
            RecipeApi.getRecipeById(argument).then((response: any) => {
                onLoad(response);
            });
        }
    }
};

export const SaveRecipe = (recipeState: IRecipe, onSuccess: (recipeId: string) => void, onFailure: () => void) => {
    RecipeApi.saveRecipe(recipeState).then((result: any) => {
        if (result.status === HttpStatus.CREATED) {
            onSuccess(result.headers.get("location"));
        } else {
            onFailure();
        }
    });
};

export const DeleteRecipe = (recipeId: string, onSuccess: () => void, onFailure: () => void) => {
    RecipeApi.deleteRecipe(recipeId).then((result: any) => {
        if (result.status === HttpStatus.OK) {
            onSuccess();
        } else {
            onFailure();
        }
    });
};
