import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes, IconDefinition} from "@fortawesome/free-solid-svg-icons";

export const BUTTONSTATE_OK = "BUTTONSTATE_OK";
export const BUTTONSTATE_PENDING = "BUTTONSTATE_PENDING";
export const BUTTONSTATE_ERROR = "BUTTONSTATE_ERROR";
export const BUTTONSTATE_HIGHLIGHTED = "BUTTONSTATE_HIGHLIGHTED";

export const BUTTONSTYLE_CLOSE_WINDOW = "BUTTONSTYLE_CLOSE_WINDOW";

export const ICON_RIGHT = "ICON_RIGHT";

interface IStyledButton {
    icon?: IconDefinition,
    iconPlacement?: string,
    text?: any,
    onClickCB: any,
    buttonState?: string,
    buttonStyle?: string,
    disabled?: boolean
}

export const StyledButton = ({
                                 icon,
                                 iconPlacement,
                                 text,
                                 onClickCB,
                                 buttonState,
                                 buttonStyle,
                                 disabled
                             }: IStyledButton) => {
    let displayText = text;
    let displayIcon = icon;

    if (displayText === undefined) {
        displayText = "";
    }

    let className = "styledButton";
    switch (buttonState) {
        case BUTTONSTATE_OK:
            className += " buttonOk";
            break;
        case BUTTONSTATE_PENDING:
            className += " buttonPending";
            break;
        case BUTTONSTATE_ERROR:
            className += " buttonError";
            break;
        case BUTTONSTATE_HIGHLIGHTED:
            className += " buttonHighlighted";
            break;
        default:
            break;
    }

    if (buttonStyle === BUTTONSTYLE_CLOSE_WINDOW) {
        displayText = "Lukk";
        displayIcon = faTimes;
    }

    if (displayIcon !== undefined) {
        if (iconPlacement === undefined) {
            displayText = (
                <>
                    <FontAwesomeIcon
                        icon={displayIcon}
                        style={{marginRight: "5px", textAlign: "right"}}
                    />
                    {` ${displayText}`}
                </>
            );
        } else if (iconPlacement === ICON_RIGHT) {
            displayText = (
                <>
                    {`${displayText} `}
                    <FontAwesomeIcon
                        icon={displayIcon}
                        style={{marginLeft: "5px", textAlign: "left"}}
                    />
                </>
            );
        }
    }

    return (
        <button
            disabled={disabled === true}
            className={className}
            onClick={() => onClickCB()}
            type="button"
        >
            {displayText}
        </button>
    );
};
