import {connect} from "react-redux";
import React, {Component} from "react";
import {faBullhorn} from "@fortawesome/free-solid-svg-icons";
import {faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import MenuTile from "./menuTile";
import SharedTile from "./sharedTile";
import AddNewTile from "./addNewTile";
import * as LABELS from "./labels";
import {friendshipsLoaded} from "../account/actions";
import ShareShoppingList from "./shareShoppingList";
import {LoaderWithText} from "../common/loaderAnimation";
import {GetFriendships} from "./utils";
import {BUTTONSTATE_HIGHLIGHTED, StyledButton} from "../components/styledButton";

import "./shoppingListMenu.css";
import {IShoppingListDto} from "../shoppingList/types";
import {IFriendshipDto} from "../account/types";

import uuidv1 from "uuid";
import {BackendCallStatusEnum, MenuDisplayEnum} from "../types";
import {loadShoppingListsAction} from "../api/shoppingListApi";
import {displayBackendError} from "../backendError";
import {Dispatch} from "redux";
import {toggleDeleteModeAction, toggleShareModeAction} from "./actions";

interface IShoppingListMenuProps {
    friendshipsLoaded: (friendships: IFriendshipDto[]) => {};
    shoppingLists: IShoppingListDto[];
    sharedShoppingLists: any;
    fetchShoppingListsStatus: BackendCallStatusEnum;
    loadShoppingLists: () => void;
    menuMode: MenuDisplayEnum;
    toggleDeleteMode: () => void;
    toggleShareMode: () => void;
}

class ShoppingListMenu extends Component<IShoppingListMenuProps> {
    componentDidMount() {
        this.props.loadShoppingLists();
        GetFriendships(this.props.friendshipsLoaded);
    }

    renderAccordingToStatus(status: BackendCallStatusEnum): JSX.Element {
        switch (status) {
            case BackendCallStatusEnum.CALL_FAILED:
                return displayBackendError();
            case BackendCallStatusEnum.CALL_IN_PROGRESS:
                return LoaderWithText("Henter handlelister ...");
            case BackendCallStatusEnum.CALL_SUCCESSFUL:
                return (
                    <>
                        <div style={{display: "flex", flexFlow: "wrap"}}>
                            {this.renderShoppingListTiles()}
                        </div>
                        <div>
                            {this.renderSharedShoppingListTiles()}
                        </div>
                        <ShareShoppingList/>
                    </>
                );
            default:
                return <></>
        }
    }

    renderShoppingListTiles(): JSX.Element[] {
        const shoppingListTiles = this.props.shoppingLists.map(
            shoppingList => (
                <MenuTile
                    shoppinglist={shoppingList}
                    key={uuidv1()}
                />
            ),
            this
        );

        if (this.props.menuMode === MenuDisplayEnum.NORMAL_MODE) {
            shoppingListTiles.push(<AddNewTile key={uuidv1()}/>);
        }

        return shoppingListTiles;
    }

    renderSharedShoppingListTiles(): JSX.Element {
        let sharedShoppingListTiles = null;
        if (Object.keys(this.props.sharedShoppingLists).length > 0) {
            sharedShoppingListTiles = Object.keys(this.props.sharedShoppingLists).map(
                authorName => {

                    const sharedLists = this.props.sharedShoppingLists[authorName];
                    let sharedTiles;
                    if (sharedLists !== undefined) {
                        sharedTiles = sharedLists.map((shoppinglist: IShoppingListDto) => (
                            <SharedTile
                                shoppinglist={shoppinglist}
                                key={uuidv1()}
                            />
                        ));
                    }
                    return (
                        <React.Fragment key={uuidv1()}>
                            <h3>{`Delt av ${authorName}`}</h3>
                            <div style={{display: "flex", flexFlow: "wrap"}}>
                                {sharedTiles}
                            </div>
                        </React.Fragment>
                    );
                }
            );
        }
        return (
            <>{sharedShoppingListTiles}</>
        )
    }

    render(): JSX.Element {
        return (
            <div className="shopping-list-menu">
                <h1>Handlelister</h1>
                <div className="shopping-list-menu-top">
                    <StyledButton
                        text={LABELS.BUTTON_REMOVE_SHOPPING_LIST}
                        icon={faTrashAlt}
                        onClickCB={this.props.toggleDeleteMode}
                        buttonState={this.props.menuMode === MenuDisplayEnum.DELETE_MODE ? BUTTONSTATE_HIGHLIGHTED : ""}
                        disabled={this.props.shoppingLists.length === 0}
                    />
                    <StyledButton
                        text={LABELS.BUTTON_SHARE_SHOPPING_LIST}
                        icon={faBullhorn}
                        onClickCB={this.props.toggleShareMode}
                        buttonState={this.props.menuMode === MenuDisplayEnum.SHARE_MODE ? BUTTONSTATE_HIGHLIGHTED : ""}
                        disabled={this.props.shoppingLists.length === 0}
                    />
                </div>
                {this.renderAccordingToStatus(this.props.fetchShoppingListsStatus)}
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    shoppingLists: state.shoppingListMenuState.shoppingLists,
    sharedShoppingLists: state.shoppingListMenuState.sharedShoppingLists,
    fetchShoppingListsStatus: state.shoppingListMenuState.fetchShoppingListsStatus,
    menuMode: state.shoppingListMenuState.menuMode
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    toggleDeleteMode: () => dispatch(toggleDeleteModeAction()),
    toggleShareMode: () => dispatch(toggleShareModeAction()),
    friendshipsLoaded: (friendships: IFriendshipDto[]) => dispatch(friendshipsLoaded(friendships)),
    loadShoppingLists: () => loadShoppingListsAction(dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShoppingListMenu);
