import React, {Component} from "react";
import {HashRouter, NavLink, Route, Switch} from "react-router-dom";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUtensils} from "@fortawesome/free-solid-svg-icons";
import SearchRecipe from "./searchRecipe/searchRecipe";
import ChangeRecipe from "./changeRecipe/changeRecipe";
import ShowRecipe from "./showRecipe/showRecipe";
import ShoppingListMenu from "./shoppingListMenu/shoppingListMenu";
import ShoppingList from "./shoppingList/shoppingList";
import AccountView from "./account/accountView";
import LoginPage from "./login/loginPage";
import LogoutPage from "./login/logoutPage";
import {friendshipsLoaded} from "./account/actions";
import {show404} from "./show404";
import {IFriendshipCollectionDto, IFriendshipDto} from "./account/types";
import uuidv1 from "uuid";
import {LoginStatusEnum} from "./types";

const authenticationService = require("./login/authenticationService");
const AccountApi = require("./api/accountApi");

interface IMainProps {
    friendshipsLoaded: (friendships: IFriendshipDto[]) => void;
}

interface IMainState {
    signedIn: LoginStatusEnum;
}

class Main extends Component<IMainProps, IMainState> {
    constructor(props: IMainProps) {
        super(props);
        this.state = {signedIn: authenticationService.userIsLoggedIn()};
        this.changeLoginStatus = this.changeLoginStatus.bind(this);
    }

    changeLoginStatus(userIsSignedIn: LoginStatusEnum) {
        this.setState({signedIn: userIsSignedIn});
    }

    render() {
        if (this.state.signedIn === LoginStatusEnum.SIGNED_IN) {
            AccountApi.getFriendships()
                .then(
                    (friendshipCollectionDto: IFriendshipCollectionDto) => this.props.friendshipsLoaded(friendshipCollectionDto.friendships));
        }

        return (
            <HashRouter>
                <div className="centerWrap">
                    <div className="backgroundLayer"/>
                    <div className="logowrap">
                        <h1 className={"mainHeader"}>
                            Smaksbanken
                            <FontAwesomeIcon icon={faUtensils}/>
                        </h1>
                    </div>
                    <div className="headerLine">
                        <div className="header">
                            <ul>{getLinks(this.state.signedIn)}</ul>
                        </div>
                    </div>
                    <div className="content">
                        <Switch>
                            {getRoutes(
                                this.state.signedIn,
                                this.changeLoginStatus
                            )}
                        </Switch>
                    </div>
                </div>
            </HashRouter>
        );
    }
}

/* eslint-disable react/jsx-indent */
const getLinks = (isSignedIn: LoginStatusEnum) =>
    isSignedIn === LoginStatusEnum.SIGNED_IN
        ? [
            <li key={uuidv1()}>
                <NavLink to="/handlelister">Handlelister</NavLink>
            </li>,
            <li key={uuidv1()}>
                <NavLink to="/oppskrifter">Oppskrifter</NavLink>
            </li>,
            <li key={uuidv1()}>
                <NavLink to="/konto">Konto</NavLink>
            </li>
        ]
        : [];

const createRoute = (path: string, component: any, exact = false) => {
    if (exact) {
        return <Route exact path={path} component={component} key={uuidv1()}/>;
    }
    return <Route path={path} component={component} key={uuidv1()}/>;
};

const getRoutes = (loginStatus: LoginStatusEnum, changeLoginStatus: (userIsSignedIn: LoginStatusEnum) => void) =>
    loginStatus === LoginStatusEnum.SIGNED_IN
        ? [
            createRoute("/oppskrifter", SearchRecipe, true),
            createRoute("/oppskrifter/les/*", ShowRecipe, true),
            createRoute("/oppskrifter/endre/*", ChangeRecipe, true),
            createRoute("/handlelister", ShoppingListMenu, true),
            createRoute("/", ShoppingListMenu, true),
            createRoute("/handlelister/*", ShoppingList),
            createRoute("/konto", AccountView),
            <Route
                key={uuidv1()}
                exact
                path="/loggut"
                render={props => (
                    <LogoutPage {...props} changeLoginStatus={changeLoginStatus}/>
                )}
            />,
            <Route key={uuidv1()} component={show404}/>
        ]
        : [
            <Route
                key={uuidv1()}
                render={props => (
                    <LoginPage {...props} changeLoginStatus={changeLoginStatus} loginStatus={loginStatus}/>
                )}
            />
        ];

const mapDispatchToProps = (dispatch: any) => ({
    friendshipsLoaded: (friendships: IFriendshipDto[]) => dispatch(friendshipsLoaded(friendships))
});

export default connect(
    null,
    mapDispatchToProps
)(Main);
