import React from "react";
import {connect} from "react-redux";
import * as LABELS from "./labels";
import {clearCategoryFilter, updateCategoryFilter} from "./actions";
import {BUTTONSTATE_HIGHLIGHTED, StyledButton} from "../components/styledButton";

interface IRecipeCategoryProps {
    categoryFilter: string[];
    category: ICategory;
    clearCategoryFilterProp: () => {};
    updateCategoryFilterProp: (value: string) => {};
}

export interface ICategory {
    Value: string;
    Description: string;
}

export interface IUnit {
    Value: string;
    Description: string;
}


const RecipeCategory = (
    {
        categoryFilter,
        category,
        clearCategoryFilterProp,
        updateCategoryFilterProp
    }: IRecipeCategoryProps) => {
    {
        let buttonState = "";

        if (categoryFilter.includes(category.Value)) {
            buttonState = BUTTONSTATE_HIGHLIGHTED;
        } else if (
            category.Value === LABELS.SHOW_ALL_BUTTON &&
            categoryFilter.length === 0
        ) {
            buttonState = BUTTONSTATE_HIGHLIGHTED;
        }

        let action;
        if (category.Value === LABELS.SHOW_ALL_BUTTON) {
            action = () => clearCategoryFilterProp();
        } else {
            action = () => updateCategoryFilterProp(category.Value);
        }

        return (
            <StyledButton
                text={category.Description}
                onClickCB={action}
                buttonState={buttonState}
            />
        );
    }
};

const mapStateToProps = (state: any) => ({
    categoryEnum: state.commonState.CategoryEnum,
    categoryFilter: state.searchRecipeState.categoryFilter
});

const mapDispatchToProps = (dispatch: any) => ({
    clearCategoryFilterProp: () => dispatch(clearCategoryFilter()),
    updateCategoryFilterProp: (category: string) => dispatch(updateCategoryFilter(category))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RecipeCategory);
