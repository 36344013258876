export enum BackendCallStatusEnum {
    CALL_IN_PROGRESS,
    CALL_SUCCESSFUL,
    CALL_FAILED
}

export enum LoginStatusEnum {
    SIGNED_IN,
    SIGNED_OUT,
    SIGNIN_IN_PROGRESS,
    SIGNIN_FAILED
}

export enum DataActionEnum {
    FETCH,
    FETCH_ALL,
    CREATE,
    DELETE,
    UPDATE,
    SHARE,
    UNSHARE
}

export enum MenuDisplayEnum {
    NORMAL_MODE,
    DELETE_MODE,
    SHARE_MODE
}