import React from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLemon} from "@fortawesome/free-regular-svg-icons";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {IRecipeDto} from "../showRecipe/types";

import uuidv1 from "uuid";

const filterOnSearchString = (searchString: string, recipeList: IRecipeDto[]) => {
    if (searchString === "") {
        return recipeList;
    }
    const filteredOnWord: IRecipeDto[] = [];
    recipeList.forEach(recipe => {
        if (recipe.name.toUpperCase().includes(searchString.toUpperCase())) {
            filteredOnWord.push(recipe);
        }
    });
    return filteredOnWord;
};

const filterSearchOnCategory = (categoryFilter: string[], recipeList: IRecipeDto[]) => {
    if (categoryFilter.length === 0) {
        return recipeList;
    }
    const filteredOnCategory: IRecipeDto[] = [];
    recipeList.forEach((recipe: IRecipeDto) => {
        recipe.categories.forEach(category => {
            if (
                categoryFilter.includes(category) &&
                !filteredOnCategory.includes(recipe)
            ) {
                filteredOnCategory.push(recipe);
            }
        });
    });
    return filteredOnCategory;
};

const filterRecipes = (recipes: IRecipeDto[], searchString: string, categoryFilter: string[]) => {
    const filteredOnString = filterOnSearchString(searchString, recipes);
    return filterSearchOnCategory(categoryFilter, filteredOnString);
};

const buildDisplayList = (recipes: IRecipeDto[], categoryEnum: string[], history: any) =>
    recipes.map(recipe => (
        <tr
            key={uuidv1()}
            onClick={() => history.push(`/oppskrifter/les/${recipe.id}`)}
        >
            <td style={{fontSize: "23px", paddingRight: "15px"}}>
                <FontAwesomeIcon icon={faLemon}/>
            </td>
            <td>{` ${recipe.name}`}</td>
        </tr>
    ));

interface IRecipeSearchDisplayProps {
    history: any;
    location: any;
    match: any;
    recipes: IRecipeDto[];
    categoryEnum: string[];
}

const RecipeSearchDisplay = ({history, recipes, categoryEnum}: IRecipeSearchDisplayProps) => {
    const displayList = buildDisplayList(recipes, categoryEnum, history);
    return (
        <table>
            <tbody>
            <tr onClick={() => history.push("/oppskrifter/endre/")}>
                <td style={{fontSize: "23px", paddingRight: "15px"}}>
                    <FontAwesomeIcon icon={faPlus}/>
                </td>
                <td style={{fontWeight: "bold"}}>Legg til ny oppskrift</td>
            </tr>
            {displayList}
            </tbody>
        </table>
    );
};

const mapStateToProps = (state: any) => ({
    categoryEnum: state.commonState.CategoryEnum,
    recipes: filterRecipes(
        state.searchRecipeState.recipes,
        state.searchRecipeState.searchString,
        state.searchRecipeState.categoryFilter
    )
});

export default withRouter(
    connect(
        mapStateToProps,
        null
    )(RecipeSearchDisplay)
);
