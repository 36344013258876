import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconDefinition} from "@fortawesome/fontawesome-common-types";

interface IIconButtonProps {
    icon: IconDefinition;
    onClickCB: () => void;
}

export const IconButton = ({icon, onClickCB}: IIconButtonProps) => (
    <button
        className="styledButton iconButton"
        onClick={() => onClickCB()}
        type="button"
    >
        {<FontAwesomeIcon icon={icon} style={{height: "22px", width: "22px"}}/>}
    </button>
);
