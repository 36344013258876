import {connect} from "react-redux";
import React from "react";
import * as LABELS from "./labels";
import {StyledSelect} from "../components/styledSelect";

import uuidv1 from "uuid";
import {IUnit} from "../searchRecipe/recipeCategory";

interface IUnitSelectionProps {
    UnitEnum: IUnit[];
    value: string;
    onChange: (value: string) => void;
    showError: () => boolean;
}

const UnitSelection = ({UnitEnum, value, onChange, showError}: IUnitSelectionProps) => {
    const unitList = UnitEnum.map(
        unit => (
            <option key={uuidv1()} value={unit.Value}>
                {unit.Description}
            </option>
        )
    );

    unitList.push(
        <option key={-1} disabled value="">
            {LABELS.PLACEHOLDER_UNIT}
        </option>
    );

    return (
        <StyledSelect
            hasError={value === "" && showError()}
            value={value}
            list={unitList}
            onChangeCB={onChange}
        />
    );
};

const mapStateToProps = (state: any) => ({
    showError: () =>
        state.changeRecipeState.stateStatus === LABELS.RECIPE_SAVE_FAILED,
    UnitEnum: state.commonState.UnitEnum
});

export default connect(
    mapStateToProps,
    null
)(UnitSelection);
