import React from "react";
import {connect} from "react-redux";
import RecipeCategory, {ICategory} from "./recipeCategory";
import * as LABELS from "./labels";
import uuidv1 from "uuid";

interface IRecipeCategoryListProps {
    CategoryEnum: ICategory[];
}

const RecipeCategoryList = ({CategoryEnum}: IRecipeCategoryListProps) => {
    const buttonList = CategoryEnum.map(
        categoryEnum => <RecipeCategory category={categoryEnum} key={uuidv1()}/>);

    buttonList.unshift(
        <RecipeCategory
            category={{
                Value: LABELS.SHOW_ALL_BUTTON,
                Description: LABELS.SHOW_ALL_BUTTON
            }}
            key={uuidv1()}
        />
    );

    return <div>{buttonList}</div>;
};

const mapStateToProps = (state: any) => ({
    CategoryEnum: state.commonState.CategoryEnum
});

export default connect(mapStateToProps, null)(RecipeCategoryList);
