import {connect} from "react-redux";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle, faPlus} from "@fortawesome/free-solid-svg-icons";
import {LABEL_ADD_NEW_SHOPPING_LIST} from "./labels";
import {Dispatch} from "redux";
import {addNewShoppingListAction} from "../api/shoppingListApi";
import {BackendCallStatusEnum} from "../types";
import {BeatLoader} from "react-spinners";

interface IAddNewTileProps {
    addNewShoppingList: () => void;
    createShoppingListStatus: BackendCallStatusEnum;
}

class AddNewTile extends React.Component<IAddNewTileProps> {
    addNewTile(): void {
        if (this.props.createShoppingListStatus !== BackendCallStatusEnum.CALL_IN_PROGRESS) {
            this.props.addNewShoppingList();
        }
    }

    renderOverlay(status: BackendCallStatusEnum): JSX.Element {
        if (status === BackendCallStatusEnum.CALL_IN_PROGRESS) {
            return (
                <div className={"MenuTile TransparentTile"}>
                    <div style={{display: "inline-block", margin: "0 auto"}}>
                        <BeatLoader size={20} color="#000" loading/>
                    </div>
                </div>
            );
        } else if (status === BackendCallStatusEnum.CALL_FAILED) {
            return (
                <div className={"MenuTile TransparentTile"}>
                    <div style={{display: "inline-block", margin: "0 auto"}}>
                        <FontAwesomeIcon style={{fontSize: "55px", color: "tomato"}} icon={faExclamationTriangle}/>
                        <div>{"En feil har oppstått, prøv igjen senere."}</div>
                    </div>
                </div>
            );
        } else {
            return <></>
        }
    }

    render(): JSX.Element {
        return (
            <div className={"MenuTile AddNewTile"} onClick={() => this.addNewTile()}>
                {this.renderOverlay(this.props.createShoppingListStatus)}
                <h2>
                    <FontAwesomeIcon icon={faPlus} style={{paddingRight: "10px"}}/>
                </h2>
                <h2>{LABEL_ADD_NEW_SHOPPING_LIST}</h2>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    createShoppingListStatus: state.shoppingListMenuState.createShoppingListStatus
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    addNewShoppingList: () => addNewShoppingListAction(dispatch)
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddNewTile);
