import {IRecipeDto} from "../showRecipe/types";

export const LOAD_RECIPES = "LOAD_RECIPES";
export const loadRecipes = (recipes: IRecipeDto[]) => ({
    type: LOAD_RECIPES,
    recipes
});

export const UPDATE_SEARCH_STRING = "UPDATE_SEARCH_STRING";
export const updateSearchString = (searchString: string) => ({
    type: UPDATE_SEARCH_STRING,
    searchString
});

export const UPDATE_CATEGORY_FILTER = "UPDATE_CATEGORY_FILTER";
export const updateCategoryFilter = (category: string) => ({
    type: UPDATE_CATEGORY_FILTER,
    category
});

export const CLEAR_CATEGORY_FILTER = "CLEAR_CATEGORY_FILTER";
export const clearCategoryFilter = () => ({
    type: CLEAR_CATEGORY_FILTER
});
