import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBullhorn, faShoppingCart} from "@fortawesome/free-solid-svg-icons";
import {faTrashAlt} from "@fortawesome/free-regular-svg-icons";


import {IShoppingListCollectionDto, IShoppingListDto} from "../shoppingList/types";
import {IFriendshipCollectionDto, IFriendshipDto} from "../account/types";
import {MenuDisplayEnum} from "../types";

const ShoppingListApi = require("../api/shoppingListApi");
const AccountApi = require("./../api/accountApi");

export const GetIconDisplay = (menuMode: MenuDisplayEnum, shoppingliststate: any) => {
    const items = shoppingliststate.shoppingListItems.length;
    const shares = shoppingliststate.friendshipShares.length;

    const firstIcon = {paddingRight: "10px"};
    const secondIcon = {
        paddingLeft: "10px",
        paddingRight: "10px"
    };

    switch (menuMode) {
        case MenuDisplayEnum.DELETE_MODE:
            return <FontAwesomeIcon icon={faTrashAlt} style={firstIcon}/>;
        case MenuDisplayEnum.SHARE_MODE:
            return (
                <>
                    <FontAwesomeIcon icon={faBullhorn} style={firstIcon}/>
                    {shares}
                </>
            );

        case MenuDisplayEnum.NORMAL_MODE:
            return (
                <>
                    <FontAwesomeIcon icon={faShoppingCart} style={firstIcon}/>
                    {items}
                    {shares > 0 ? (
                        <>
                            <FontAwesomeIcon icon={faBullhorn} style={secondIcon}/>
                            {shares}
                        </>
                    ) : null}
                </>
            );

        default:
            throw new Error("Unable to resolve icon");
    }
};

export const GetFriendships = (friendshipsLoaded: (friendships: IFriendshipDto[]) => {}) => {
    AccountApi.getFriendships().then((friendshipCollectionDto: IFriendshipCollectionDto) => friendshipsLoaded(friendshipCollectionDto.friendships));
};

export const FindSharesByShoppingListId = (shoppingLists: IShoppingListDto[], shoppingListId: string) => {
    const shoppingList = shoppingLists.filter(list => list.id === shoppingListId);

    if (shoppingList !== undefined && shoppingList.length > 0) {
        return shoppingList[0].friendshipShares;
    }
    return [];
};

export const SendShareShoppingList = (
    shoppingListId: string,
    friendshipId: string,
    dispatchUpdateShoppingLists: (shoppingLists: IShoppingListCollectionDto) => {}
) => {
    ShoppingListApi.shareShoppingList(shoppingListId, friendshipId).then((result: IShoppingListCollectionDto) =>
        dispatchUpdateShoppingLists(result)
    );
};

export const SendUnshareShoppingList = (
    shoppingListId: string,
    friendshipId: string,
    dispatchUpdateShoppingLists: (shoppingLists: IShoppingListCollectionDto) => {}
) => {
    ShoppingListApi.unShareShoppingList(shoppingListId, friendshipId).then(
        (result: IShoppingListCollectionDto) => dispatchUpdateShoppingLists(result)
    );
};
