import capitalize from "capitalize";
import {connect} from "react-redux";
import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import ShoppingListItem from "./shoppingListItem";
import ShoppingListName from "./shoppingListName";
import {addItemToShoppingList} from "./actions";
import {IconButton} from "../components/iconButton";
import {LoaderWithText} from "../common/loaderAnimation";
import "./shoppingList.css";
import {IShoppingList} from "./types";
import uuidv1 from "uuid";
import {getShoppingListAction} from "../api/shoppingListApi";
import {BackendCallStatusEnum} from "../types";

interface IShoppingListProps {
    match: any;
    history: any;
    location: any;
    shoppingListSuggestions: string[];
    shoppingList: IShoppingList;
    onAddItemToShoppingList: (uuid: string, newItem: string) => {}
    getShoppingList: (shoppingListId: string) => void;
    isFetching: BackendCallStatusEnum;
    isUpdating: BackendCallStatusEnum;
}

interface IShoppingListState {
    newItem: string;
}

class ShoppingList extends Component<IShoppingListProps, IShoppingListState> {
    constructor(props: IShoppingListProps) {
        super(props);
        this.state = {newItem: ""};
        this.onAddItemToList = this.onAddItemToList.bind(this);
    }

    componentDidMount() {
        const shoppinglistId = this.props.location.pathname.split("/").pop();
        if (shoppinglistId !== null) {
            this.props.getShoppingList(shoppinglistId);
        }
    }

    onAddItemToList() {
        if (this.state.newItem !== "") {
            this.props.onAddItemToShoppingList(
                uuidv1(),
                capitalize(this.state.newItem)
            );
            this.setState({newItem: ""});
        }
    }

    render() {
        return (
            <div className="shopping-list__wrapper">
                {this.renderAccordingToStatus()}
            </div>
        );
    }

    renderAccordingToStatus(): JSX.Element {
        switch (this.props.isFetching) {
            case BackendCallStatusEnum.CALL_SUCCESSFUL:
                const shoppingListItems = Array.from(
                    this.props.shoppingList.items.keys()
                ).map(uuid => <ShoppingListItem uuid={uuid} key={uuid}/>);

                const suggestions = this.props.shoppingListSuggestions.map(suggestion => (
                    <option value={suggestion} key={uuidv1()}/>
                ));

                const newItemInput = <>
                    <IconButton onClickCB={() => this.onAddItemToList()} icon={faPlus}/>
                    <input
                        list="shoppinglistsuggestions"
                        placeholder="Hva vil du legge til på listen?"
                        value={this.state.newItem}
                        onChange={event => this.setState({newItem: event.target.value})}
                        onKeyPress={event => {
                            if (event.key === "Enter") this.onAddItemToList();
                        }}
                    />
                    <datalist id="shoppinglistsuggestions">{suggestions}</datalist>
                </>;

                return (
                    <>
                        <div className="shopping-list__name">
                            <ShoppingListName/>
                        </div>
                        <div className="shopping-list__new-item">{newItemInput}</div>
                        <div className="shopping-list__items">{shoppingListItems}</div>
                    </>
                );
            case BackendCallStatusEnum.CALL_IN_PROGRESS:
                return LoaderWithText("Henter handleliste ...");
            case BackendCallStatusEnum.CALL_FAILED:
                return <></>;
            default:
                return <></>;
        }
    }
}

const mapStateToProps = (state: any) => ({
    shoppingList: state.shoppingListState.shoppingList,
    shoppingListSuggestions: state.shoppingListState.suggestions,
    isFetching: state.shoppingListState.isFetching,
    isUpdating: state.shoppingListState.isUpdating,
});

const mapDispatchToProps = (dispatch: any) => ({
    getShoppingList: (shoppingListId: string) => getShoppingListAction(dispatch, shoppingListId),
    onAddItemToShoppingList: (newUuid: string, newDescription: string) =>
        dispatch(addItemToShoppingList(newUuid, newDescription)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShoppingList));
