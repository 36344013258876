import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faSignOutAlt, faUsers} from "@fortawesome/free-solid-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import AddFriendModal from "./addFriendModal";
import FriendListModal from "./friendListModal";
import oscarSmile3 from "./../media/oscar_smile_3.png";

const AuthenticationService = require("../login/authenticationService");

const getMenuItem = (icon: IconProp, text: string, onClick: () => {}) => (
    <div style={{cursor: "pointer", display: "flex", alignItems: "center", paddingBottom: "5px", paddingTop: "5px"}}
         onClick={onClick}>
        <div className="accountIconStyle">
            <FontAwesomeIcon icon={icon} style={{fontSize: "50px"}}/>
        </div>
        <div style={{paddingLeft: "10px"}}>{text}</div>
    </div>
);

const createModalOpenButton = (icon: IconProp, text: string) => {
    return (<div style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        paddingBottom: "5px",
        paddingTop: "5px"
    }}>
        <div className="accountIconStyle">
            <FontAwesomeIcon icon={icon} style={{fontSize: "50px"}}/>
        </div>
        <div style={{paddingLeft: "10px"}}>{text}</div>
    </div>)
};


interface IAccountViewProps {
    history: any;
    location: any;
    match: any;
}

const AccountView = ({history}: IAccountViewProps) => (
    <div className="account-view">
        <h1>{`Hei, ${AuthenticationService.getDisplayName()}!`}</h1>
        <img src={oscarSmile3} height="120" alt="oscar_smile_3"/>
        <h2>Her kan du administrere kontoen din</h2>
        <div>
            <AddFriendModal clickPanel={createModalOpenButton(faPlus, "Send en venneforespørsel")}/>
            <FriendListModal clickPanel={createModalOpenButton(faUsers, "Åpne vennelisten din")}/>
            {getMenuItem(faSignOutAlt, "Logg ut av smaksbanken", () =>
                history.push("/loggut")
            )}
        </div>
    </div>
);

export default withRouter(
    connect(
        null,
        null
    )(AccountView)
);
