import React from "react";

import "./styledCheckbox.css";

interface IStyledCheckboxProps {
    onChange: () => void;
    isChecked: boolean;
    value: any;
    textlabel: string;
}

export const StyledCheckbox = ({onChange, isChecked, value, textlabel}: IStyledCheckboxProps) => {
    const checkbox = (
        <input
            onChange={onChange}
            checked={isChecked}
            type="checkbox"
            id={value}
            value={value}
        />
    );

    return (
        <label className="container">
            {textlabel}
            {checkbox}
            <span className="checkmark"/>
        </label>
    );
};
