import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {openShareShoppingListModal} from "./actions";
import {GetIconDisplay} from "./utils";
import {IShoppingListDto} from "../shoppingList/types";
import {BackendCallStatusEnum, MenuDisplayEnum} from "../types";
import {deleteShoppingListAction} from "../api/shoppingListApi";
import {Dispatch} from "redux";
import {BeatLoader} from "react-spinners";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";

const showShoppingList = (id: string, history: any) => {
    history.push(`/handlelister/${id}`);
};

interface IMenuTileProps {
    location: any;
    history: any;
    match: any;
    menuMode: MenuDisplayEnum;
    deleteShoppingList: any;
    openShareModal: any;
    shoppinglist: IShoppingListDto;
    deleteShoppingListStatus: BackendCallStatusEnum;
}

interface IMenuTileState {
    isBeingDeleted: boolean;
}

class MenuTile extends Component<IMenuTileProps, IMenuTileState> {
    constructor(props: IMenuTileProps) {
        super(props);
        this.state = {
            isBeingDeleted: false
        }
    }

    renderOverlay(status: BackendCallStatusEnum): JSX.Element {
        if (status === BackendCallStatusEnum.CALL_IN_PROGRESS) {
            return (
                <div className={"MenuTile TransparentTile"}>
                    <div style={{display: "inline-block", margin: "0 auto"}}>
                        <BeatLoader size={20} color="#000" loading/>
                    </div>
                </div>
            );
        } else if (status === BackendCallStatusEnum.CALL_FAILED) {
            return (
                <div className={"MenuTile TransparentTile"}>
                    <div style={{display: "inline-block", margin: "0 auto"}}>
                        <FontAwesomeIcon style={{fontSize: "55px", color: "tomato"}} icon={faExclamationTriangle}/>
                        <div>{"En feil har oppstått ved sletting, prøv igjen senere."}</div>
                    </div>
                </div>
            );
        } else {
            return <></>
        }
    }

    render() {
        let className;
        let onClick;

        switch (this.props.menuMode) {
            case MenuDisplayEnum.DELETE_MODE:
                className = "MenuTile DeleteTile";
                onClick = () => {
                    this.props.deleteShoppingList();
                    this.setState({isBeingDeleted: true})
                };
                break;
            case MenuDisplayEnum.SHARE_MODE:
                className = "MenuTile ShareTile";
                onClick = () => this.props.openShareModal();
                break;
            case MenuDisplayEnum.NORMAL_MODE:
                className = "MenuTile ExistingTile";
                onClick = () => showShoppingList(this.props.shoppinglist.id, this.props.history);
                break;
            default:
                break;
        }

        return (
            <div className={className} onClick={onClick}>
                {this.state.isBeingDeleted ? this.renderOverlay(this.props.deleteShoppingListStatus) : <></>}
                <h2>{GetIconDisplay(this.props.menuMode, this.props.shoppinglist)}</h2>
                <h2>{this.props.shoppinglist.name}</h2>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch, props: any) => ({
    openShareModal: () => dispatch(openShareShoppingListModal(props.shoppinglist.id)),
    deleteShoppingList: () => deleteShoppingListAction(dispatch, props.shoppinglist.id)
});

const mapStateToProps = (state: any) => ({
    menuMode: state.shoppingListMenuState.menuMode,
    deleteShoppingListStatus: state.shoppingListMenuState.deleteShoppingListStatus
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MenuTile));
