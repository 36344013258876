import {connect} from "react-redux";
import React, {Component} from "react";
import {faEdit, faSave, faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import {IconButton} from "../components/iconButton";
import {checkShoppingListItem, deleteShoppingListItem, updateShoppingListItem} from "./actions";
import capitalize from "capitalize";

interface IShoppingListItemProps {
    description: string;
    checked: boolean;
    deleteItem: () => {};
    changeItem: (updatedDescription: string) => {};
    checkItem: () => {};
    uuid: string;
}

interface IShoppingListItemState {
    updatedDescription: string;
    isEditing: boolean;
}


class ShoppingListItem extends Component<IShoppingListItemProps, IShoppingListItemState> {
    constructor(props: IShoppingListItemProps) {
        super(props);
        this.state = {
            isEditing: false,
            updatedDescription: this.props.description
        };
    }

    getEditButton() {
        return (
            <IconButton
                onClickCB={() => this.setState({isEditing: true})}
                icon={faEdit}
            />
        );
    }

    getSaveButton() {
        return (
            <IconButton
                onClickCB={() => this.handleItemDescriptionChanged()}
                icon={faSave}
            />
        );
    }

    getDisplayText() {
        return (
            <div
                className={
                    this.props.checked
                        ? "shopping-list__item-text-display shopping-list__item-text-checked"
                        : "shopping-list__item-text-display"
                }
            >
                {this.props.description}
            </div>
        );
    }

    getEditControl() {
        return (
            <input
                onKeyPress={event => {
                    if (event.key === "Enter") {
                        this.handleItemDescriptionChanged();
                    }
                }}
                value={this.state.updatedDescription}
                onChange={event =>
                    this.setState({updatedDescription: event.target.value})
                }
            />
        );
    }

    handleItemDescriptionChanged() {
        if (this.state.updatedDescription === "") {
            this.props.deleteItem();
        } else {
            this.props.changeItem(capitalize(this.state.updatedDescription));
        }
        this.setState({isEditing: false});
    }

    checkItem() {
        if (!this.state.isEditing) this.props.checkItem();
    }

    render() {
        return (
            <div className="shopping-list__item" key={this.props.uuid}>
                <div>
                    <IconButton
                        icon={faTrashAlt}
                        onClickCB={() => this.props.deleteItem()}
                    />
                </div>
                <div>
                    {this.state.isEditing ? this.getSaveButton() : this.getEditButton()}
                </div>
                <div
                    className="shopping-list__item-text"
                    onClick={() => this.checkItem()}
                >
                    {this.state.isEditing ? this.getEditControl() : this.getDisplayText()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any, props: any) => ({
    checked: state.shoppingListState.shoppingList.items.get(props.uuid).checked,
    description: state.shoppingListState.shoppingList.items.get(props.uuid)
        .description
});

const mapDispatchToProps = (dispatch: any, props: any) => ({
    checkItem: () => dispatch(checkShoppingListItem(props.uuid)),
    deleteItem: () => dispatch(deleteShoppingListItem(props.uuid)),
    changeItem: (description: string) =>
        dispatch(updateShoppingListItem(props.uuid, description))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShoppingListItem);
