import {IRecipe, IRecipeDto} from "../showRecipe/types";
import {ICategory} from "../searchRecipe/recipeCategory";

export const ADD_RECIPE_GROUP = "ADD_RECIPE_GROUP";
export const addRecipeGroup = () => ({
    type: ADD_RECIPE_GROUP
});

export const CHANGE_GROUP_POSITION = "CHANGE_GROUP_POSITION";
export const changeGroupPosition = (group: string, position: string) => ({
    type: CHANGE_GROUP_POSITION,
    group,
    position
});

export const CHANGE_INGREDIENT_POSITION = "CHANGE_INGREDIENT_POSITION";
export const changeIngredientPosition = (group: string, ingredient: string, position: string) => ({
    type: CHANGE_INGREDIENT_POSITION,
    group,
    ingredient,
    position
});

export const INGREDIENT_PROP_AMOUNT = "INGREDIENT_PROP_AMOUNT";
export const INGREDIENT_PROP_DELETE = "INGREDIENT_PROP_DELETE";
export const INGREDIENT_PROP_NAME = "INGREDIENT_PROP_NAME";
export const INGREDIENT_PROP_NEW = "INGREDIENT_PROP_NEW";
export const INGREDIENT_PROP_UNIT = "INGREDIENT_PROP_UNIT";
export const CHANGE_INGREDIENT_PROPERTY = "CHANGE_INGREDIENT_PROPERTY";
export const changeIngredientProperty = (
    group: string,
    ingredient: string | null,
    property: string,
    value?: string
) => ({
    type: CHANGE_INGREDIENT_PROPERTY,
    group,
    ingredient,
    property,
    value
});

export const CHANGE_RECIPE_CATEGORY = "CHANGE_RECIPE_CATEGORY";
export const changeRecipeCategory = (category: string   ) => ({
    type: CHANGE_RECIPE_CATEGORY,
    category
});

export const CHANGE_RECIPE_GROUP_NAME = "CHANGE_RECIPE_GROUP_NAME";
export const changeRecipeGroupName = (uuid: string, name: string) => ({
    type: CHANGE_RECIPE_GROUP_NAME,
    uuid,
    name
});

export const CHANGE_RECIPE_INSTRUCTIONS = "CHANGE_RECIPE_INSTRUCTIONS";
export const changeRecipeInstructions = (instructions: string) => ({
    type: CHANGE_RECIPE_INSTRUCTIONS,
    instructions
});

export const CHANGE_RECIPE_NAME = "CHANGE_RECIPE_NAME";
export const changeRecipeName = (name: string) => ({
    type: CHANGE_RECIPE_NAME,
    name
});

export const CLEAR_RECIPE = "CLEAR_RECIPE";
export const clearRecipe = () => ({
    type: CLEAR_RECIPE
});

export const DELETE_RECIPE_GROUP = "DELETE_RECIPE_GROUP";
export const deleteRecipeGroup = (uuid: string) => ({
    type: DELETE_RECIPE_GROUP,
    uuid
});

export const RECIPE_API_RESULT = "RECIPE_API_RESULT";
export const recipeApiResult = (apiResult: any) => ({
    type: RECIPE_API_RESULT,
    apiResult
});

export const RECIPE_LOADED = "RECIPE_LOADED";
export const recipeLoaded = (loadedRecipe: IRecipeDto) => ({
    type: RECIPE_LOADED,
    loadedRecipe
});
