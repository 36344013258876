import {IShoppingList} from "./types";
import {BackendCallStatusEnum, DataActionEnum} from "../types";

export const ADD_ITEM_TO_SHOPPING_LIST = "ADD_ITEM_TO_SHOPPING_LIST";
export const addItemToShoppingList = (newUuid: string, newDescription: string) => ({
  type: ADD_ITEM_TO_SHOPPING_LIST,
  newUuid,
  newDescription
});

export const CHECK_SHOPPING_LIST_ITEM = "CHECK_SHOPPING_LIST_ITEM";
export const checkShoppingListItem = (uuid: string) => ({
  type: CHECK_SHOPPING_LIST_ITEM,
  uuid
});

export const DELETE_SHOPPING_LIST_ITEM = "DELETE_SHOPPING_LIST_ITEM";
export const deleteShoppingListItem = (uuid:string) => ({
  type: DELETE_SHOPPING_LIST_ITEM,
  uuid
});

export const SHOPPING_LIST_LOADED = "SHOPPING_LIST_LOADED";
export const shoppingListLoaded = (shoppingList: IShoppingList) => ({
  type: SHOPPING_LIST_LOADED,
  shoppingList
});

export const UPDATE_SHOPPING_LIST_ITEM = "UPDATE_SHOPPING_LIST_ITEM";
export const updateShoppingListItem = (uuid: string, updatedDescription: string) => ({
  type: UPDATE_SHOPPING_LIST_ITEM,
  uuid,
  updatedDescription
});

export const UPDATE_SHOPPING_LIST_NAME = "UPDATE_SHOPPING_LIST_NAME";
export const updateShoppingListName = (newName: string) => ({
  type: UPDATE_SHOPPING_LIST_NAME,
  newName
});

export const UPDATE_SHOPPINGLIST_BACKEND_STATUS = "UPDATE_SHOPPINGLIST_BACKEND_STATUS";
export const updateShoppinglistBackendStatus = (status: BackendCallStatusEnum, dataAction: DataActionEnum) => ({
  type: UPDATE_SHOPPINGLIST_BACKEND_STATUS,
  status: status,
  dataAction: dataAction
});