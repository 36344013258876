import {connect} from "react-redux";
import React, {Component} from "react";
import {faEdit, faSave} from "@fortawesome/free-regular-svg-icons";
import {updateShoppingListName} from "./actions";
import {IconButton} from "../components/iconButton";

interface IShoppingListNameProps {
    shoppingListName: string;
    onUpdateShoppingListName: (updatedName: string) => {}
}

interface IShoppingListNameState {
    updatedName: string;
    isEditing: boolean;
}

class ShoppingListName extends Component<IShoppingListNameProps, IShoppingListNameState> {
    constructor(props: IShoppingListNameProps) {
        super(props);
        this.state = {
            isEditing: false,
            updatedName: ""
        };

        this.updateName = this.updateName.bind(this);
    }

    getEditButton() {
        return (
            <IconButton
                onClickCB={() =>
                    this.setState({
                        isEditing: true,
                        updatedName: this.props.shoppingListName
                    })
                }
                icon={faEdit}
            />
        );
    }

    getSaveButton() {
        return <IconButton onClickCB={this.updateName} icon={faSave}/>;
    }

    getNameDisplay() {
        return <h1>{this.props.shoppingListName}</h1>;
    }

    getNameEditControl() {
        return (
            <input
                onChange={event => this.setState({updatedName: event.target.value})}
                onKeyPress={event => {
                    if (event.key === "Enter") this.updateName();
                }}
                placeholder="Navn på handlelisten..."
                value={this.state.updatedName}
            />
        );
    }

    updateName() {
        if (this.state.updatedName !== "") {
            this.props.onUpdateShoppingListName(this.state.updatedName);
        }
        this.setState({isEditing: false});
    }

    render() {
        return (
            <>
                <div>
                    {this.state.isEditing ? this.getSaveButton() : this.getEditButton()}
                </div>
                <div>
                    {this.state.isEditing
                        ? this.getNameEditControl()
                        : this.getNameDisplay()}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state: any) => ({
    shoppingListName: state.shoppingListState.shoppingList.name
});

const mapDispatchToProps = (dispatch: any) => ({
    onUpdateShoppingListName: (newName: string) => dispatch(updateShoppingListName(newName))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShoppingListName);
