import {connect} from "react-redux";
import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {faArrowsAlt, faPlus} from "@fortawesome/free-solid-svg-icons";
import {BUTTONSTATE_HIGHLIGHTED, StyledButton} from "../components/styledButton";
import RecipeName from "./recipeName";
import RecipeInstructions from "./recipeInstructions";
import RecipeCategories from "./recipeCategories";
import IngredientList from "./ingredientList";
import {DeleteRecipe, GetButtonIcon, GetButtonState, HandleLoadRecipe, SaveRecipe} from "./utils";

import {
    BUTTON_LABEL_ADD_INGREDIENT,
    BUTTON_LABEL_DELETE_RECIPE,
    BUTTON_LABEL_MOVE_INGREDIENT,
    BUTTON_LABEL_SAVE_RECIPE,
    RECIPE_SAVE_FAILED,
    RECIPE_SAVED_OK,
    STATUS_DELETE_ERROR
} from "./labels";

import {addRecipeGroup, clearRecipe, recipeApiResult, recipeLoaded} from "./actions";
import ConfirmationModal from "../components/confirmationModal";
import {faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import {Dispatch} from "redux";
import {IIngredientGroup, IRecipe, IRecipeDto} from "../showRecipe/types";
import {showNavigationArrows} from "./commonNavigation";

interface IChangeRecipeProps {
    history: any;
    location: any;
    match: any;
    addRecipeGroup: () => void;
    recipeLoaded: (recipe: IRecipeDto) => void;
    clearRecipe: () => void;
    ingredientGroups: Map<string, IIngredientGroup>;
    recipeState: IRecipe;
    stateStatus: string;
    recipeApiResult: (result: string) => void;
}


interface IChangeRecipeState {
    canMoveIngredients: boolean;
}

class ChangeRecipe extends Component<IChangeRecipeProps, IChangeRecipeState> {
    private unlisten: any;

    constructor(props: IChangeRecipeProps) {
        super(props);
        this.state = {canMoveIngredients: false};
        this.toggleMoveIngredients = this.toggleMoveIngredients.bind(this);
    }

    componentWillMount() {
        this.unlisten = this.props.history.listen((location: Location) => {
            HandleLoadRecipe(
                location,
                (loadedRecipe: IRecipeDto) => this.props.recipeLoaded(loadedRecipe),
                () => this.props.clearRecipe()
            );
        });
    }

    componentDidMount() {
        HandleLoadRecipe(
            this.props.location,
            (loadedRecipe: IRecipeDto) => this.props.recipeLoaded(loadedRecipe),
            () => this.props.clearRecipe()
        );
    }

    componentWillUnmount() {
        this.unlisten();
    }

    toggleMoveIngredients() {
        const previousState = this.state.canMoveIngredients;
        this.setState({
            canMoveIngredients: !previousState
        });
    }

    render() {
        const numberOfGroups = this.props.ingredientGroups.size;

        const ingredientLists = Array.from(
            this.props.ingredientGroups.values()
        ).map((group: IIngredientGroup, index: number) => {
            const navigationArrowsVisibility = showNavigationArrows(
                numberOfGroups,
                index
            );
            return (
                <IngredientList
                    groupUuid={group.uuid}
                    key={group.uuid}
                    showUpArrow={navigationArrowsVisibility.up}
                    showDownArrow={navigationArrowsVisibility.down}
                    canMoveIngredients={this.state.canMoveIngredients}
                />
            );
        });

        const functions = (
            <div className="change-recipe-topwrapper">
                <StyledButton
                    text={BUTTON_LABEL_SAVE_RECIPE}
                    buttonState={GetButtonState(this.props.stateStatus)}
                    icon={GetButtonIcon(this.props.stateStatus)}
                    onClickCB={() =>
                        SaveRecipe(
                            this.props.recipeState,
                            (location: string) => {
                                this.props.recipeApiResult(RECIPE_SAVED_OK);
                                this.props.history.push(location);
                            },
                            () => this.props.recipeApiResult(RECIPE_SAVE_FAILED)
                        )
                    }
                />
                <ConfirmationModal
                    buttonIcon={faTrashAlt}
                    buttonText={BUTTON_LABEL_DELETE_RECIPE}
                    acceptButtonText={"Ja, slett oppskriften"}
                    modalHeader={"Er du sikker?"}
                    modalText={"Er du helt sikker på at du vil slette oppskriften?"}
                    onAccept={() => DeleteRecipe(
                        this.props.recipeState.id,
                        () => this.props.history.push("/oppskrifter"),
                        () => this.props.recipeApiResult(STATUS_DELETE_ERROR)
                    )}/>
                <StyledButton
                    text={BUTTON_LABEL_ADD_INGREDIENT}
                    onClickCB={this.props.addRecipeGroup}
                    icon={faPlus}
                />
                <StyledButton
                    text={BUTTON_LABEL_MOVE_INGREDIENT}
                    onClickCB={() => this.toggleMoveIngredients()}
                    buttonState={
                        this.state.canMoveIngredients ? BUTTONSTATE_HIGHLIGHTED : ""
                    }
                    icon={faArrowsAlt}
                />
            </div>
        );


        return (
            <div>
                <h1 style={{marginBottom: "5px"}}>Legg til/Endre oppskrift</h1>
                <div className="change-recipe-functionkeys">{functions}</div>
                <div className="change-recipe-wrapper">
                    <div className="change-recipe-wrapper-left">
                        <div className="change-recipe-name">
                            <RecipeName/>
                        </div>
                        <div className="change-recipe-ingredientlist">
                            {ingredientLists}
                        </div>
                    </div>
                    <div className="change-recipe-wrapper-right">
                        <div className="change-recipe-instructions">
                            <RecipeInstructions/>
                        </div>
                        <div className="change-recipe-categories">
                            <RecipeCategories/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    ingredientGroups: state.changeRecipeState.ingredientGroups,
    stateStatus: state.changeRecipeState.stateStatus,
    recipeState: state.changeRecipeState
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    addRecipeGroup: () => dispatch(addRecipeGroup()),
    recipeLoaded: (loadedRecipe: IRecipeDto) => dispatch(recipeLoaded(loadedRecipe)),
    clearRecipe: () => dispatch(clearRecipe()),
    recipeApiResult: (result: String) => dispatch(recipeApiResult(result))
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ChangeRecipe)
);
