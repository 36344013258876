import {addSeconds} from "date-fns";
import {getBackendURI} from "../backendconfig";
import {LoginStatusEnum} from "../types";

const SIGNED_IN_SUBJECT = "signed_in_subject";
const SIGNED_IN_NAME = "signed_in_name";
const ACCESS_TOKEN = "access_token";
const ACCESS_TOKEN_TIMEOUT = "access_token_timeout";
const REFRESH_TOKEN = "refresh_token";

const storeValue = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
};

const readValue = (key: string) => {
    const item: string | null = localStorage.getItem(key);
    return item === null ? null : JSON.parse(item);
};

const removeValue = (key: string) => localStorage.removeItem(key);

export const setSignedInUser = (displayName: string, subject: string, refreshToken: string) => {
    storeValue(SIGNED_IN_NAME, displayName);
    storeValue(SIGNED_IN_SUBJECT, subject);
    storeValue(REFRESH_TOKEN, refreshToken);
};

export const updateAccessToken = (
    accessToken: string,
    refreshToken: string,
    secondsUntilAccessTokenTimeout: number
) => {
    storeValue(REFRESH_TOKEN, refreshToken);
    storeValue(ACCESS_TOKEN, accessToken);
    storeValue(
        ACCESS_TOKEN_TIMEOUT,
        addSeconds(new Date(), secondsUntilAccessTokenTimeout)
    );
};

export const getAccessToken = () => readValue(ACCESS_TOKEN);

export const getAccessTokenTimeout = () => readValue(ACCESS_TOKEN_TIMEOUT);

export const getRefreshToken = () => readValue(REFRESH_TOKEN);

export const getDisplayName = () => readValue(SIGNED_IN_NAME);

export const signOutUser = () => {
    removeValue(SIGNED_IN_SUBJECT);
    removeValue(SIGNED_IN_NAME);
    removeValue(ACCESS_TOKEN);
    removeValue(ACCESS_TOKEN_TIMEOUT);
    removeValue(REFRESH_TOKEN);
};

export const authenticateUser = (authorizationCode: string) =>
    fetch(`${getBackendURI()}/action/login`, {
        method: "POST",
        headers: {
            Authorization: authorizationCode,
            "Content-Type": "application/json"
        }
    }).then(result => result.json());

export const userIsLoggedIn = (): LoginStatusEnum => {
    const signedInSubject: string | null = localStorage.getItem(SIGNED_IN_SUBJECT);
    if (signedInSubject !== null && (JSON.parse(signedInSubject) !== null)) {
        return LoginStatusEnum.SIGNED_IN;
    } else {
        return LoginStatusEnum.SIGNED_OUT;
    }
};

