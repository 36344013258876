import {withRouter} from "react-router-dom";
import {LoginStatusEnum} from "../types";

const authenticationService = require("./authenticationService");

interface ILogoutPageProps {
    changeLoginStatus: (loginStatus: LoginStatusEnum) => void;
    history: any;
    location: any;
    match: any;
}

const LogoutPage = ({changeLoginStatus, history}: ILogoutPageProps) => {
    if (authenticationService.userIsLoggedIn() === LoginStatusEnum.SIGNED_IN) {
        authenticationService.signOutUser();
        changeLoginStatus(LoginStatusEnum.SIGNED_OUT);
    }

    history.push("/");

    return null;
};

export default withRouter(LogoutPage);
