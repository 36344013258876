import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faUser} from "@fortawesome/free-regular-svg-icons";
import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import {faCheck} from "@fortawesome/free-solid-svg-icons/faCheck";
import {faUserAltSlash} from "@fortawesome/free-solid-svg-icons";
import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes";
import {FriendshipInvitationStatus} from "./types";
import userThumbnail1 from "../media/user_thumbnail_1.png";
import userThumbnail2 from "../media/user_thumbnail_2.png";
import userThumbnail3 from "../media/user_thumbnail_3.png";

interface IFriendPanelProps {
    userName: string;
    userEmail: string;
    pictureUrl: string;
    friendshipStatus: FriendshipInvitationStatus;
    onAccept: () => void;
    onReject: () => void;
}

interface IFriendPanelState {
    randomNumberBetweenOneAndThree: number;
}

class FriendPanel extends Component<IFriendPanelProps, IFriendPanelState> {
    constructor(props: IFriendPanelProps) {
        super(props);
        this.state = {randomNumberBetweenOneAndThree: Math.floor(Math.random() * 3) + 1}
    }

    getButton(friendshipStatus: FriendshipInvitationStatus) {
        switch (friendshipStatus) {
            case FriendshipInvitationStatus.friends:
                return <>
                    {createButton("Fjern venn", faUserAltSlash, this.props.onReject)}
                </>;
            case FriendshipInvitationStatus.invitation_sent:
                return <>
                    {createButton("Trekk tilbake forespørsel", faTimes, this.props.onReject)}
                </>;
            case FriendshipInvitationStatus.friend_request:
                return <>
                    {createButton("Aksepter", faCheck, this.props.onAccept)}
                    {createButton("Slett", faTimes, this.props.onReject)}
                </>;
        }
    };

    getThumbnail() {
        if (this.props.pictureUrl !== null) {
            return this.props.pictureUrl;
        } else {
            switch (this.state.randomNumberBetweenOneAndThree) {
                case 1:
                    return userThumbnail1;
                case 2:
                    return userThumbnail2;
                case 3:
                    return userThumbnail3;
                default:
                    return userThumbnail1;
            }
        }
    }

    render() {
        return (
            <div className={"friend-panel"}>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "space-evenly"}}>
                    <div style={{fontWeight: "bold"}}>
                        <FontAwesomeIcon icon={faUser} style={{width: "30px"}}/>
                        {this.props.userName}
                    </div>
                    <div>
                        <FontAwesomeIcon icon={faEnvelope} style={{width: "30px"}}/>
                        {this.props.userEmail}
                    </div>
                    <div>
                        {this.getButton(this.props.friendshipStatus)}
                    </div>
                </div>
                <div>
                    <img
                        src={this.getThumbnail()}
                        className={"friend-panel-thumbnail"} alt="oscarThumbnail"/>
                </div>
            </div>
        );
    }
}

const createButton = (text: string, icon: IconDefinition, onClickFunction: () => void) => {
    return <button
        onClick={onClickFunction}
        className={"friend-panel-button"}>
        <FontAwesomeIcon icon={icon} style={{paddingRight: "5px"}}/>{text}
    </button>
};

export default FriendPanel;
