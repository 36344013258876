import { getBackendURI } from "../backendconfig";
import { handleHttpStatus } from "./statusHandler";
import { resolveAccessToken } from "./accessToken";

export const sendFriendRequest = email =>
  resolveAccessToken()
    .then(accessToken =>
      fetch(`${getBackendURI()}/api/friends/`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: accessToken
        },
        body: email
      })
    )
    .then(result => handleHttpStatus(result));

export const getFriendships = () =>
  resolveAccessToken()
    .then(accessToken =>
      fetch(`${getBackendURI()}/api/friends/`, {
        method: "GET",
        headers: {
          Authorization: accessToken
        }
      })
    )
    .then(result => handleHttpStatus(result));

export const acceptFriendRequest = friendshipId =>
  resolveAccessToken()
    .then(accessToken =>
      fetch(`${getBackendURI()}/api/friends/${friendshipId}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: accessToken
        }
      })
    )
    .then(result => handleHttpStatus(result));

export const declineFriendRequest = friendshipId =>
  resolveAccessToken()
    .then(accessToken =>
      fetch(`${getBackendURI()}/api/friends/${friendshipId}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: accessToken
        }
      })
    )
    .then(result => handleHttpStatus(result));
