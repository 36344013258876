import React from "react";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {updateSearchString} from "./actions";

interface ISearchFieldProps {
    dispatchSearch: (searchText: string) => {}
}

const SearchField = ({dispatchSearch}: ISearchFieldProps) => (
    <div style={{padding: "5px"}}>
        <label htmlFor="name">
            <FontAwesomeIcon
                style={{paddingRight: "10px", fontSize: "25px"}}
                icon={faSearch}
            />
        </label>
        <input
            id="name"
            onChange={event => dispatchSearch(event.target.value)}
        />
    </div>
);

const mapDispatchToProps = (dispatch: any) => ({
    dispatchSearch: (searchText: string) =>
        dispatch(updateSearchString(searchText))
});

export default connect(
    null,
    mapDispatchToProps
)(SearchField);
