import React, {Component} from "react";
import {connect} from "react-redux";
import {friendshipsLoaded} from "./actions";
import {FriendshipInvitationStatus, IFriendshipCollectionDto, IFriendshipDto} from "./types";
import ReactModal from "react-modal";
import FriendPanel from "./friendPanel";
import {BUTTONSTYLE_CLOSE_WINDOW, StyledButton} from "../components/styledButton";
import uuidv1 from "uuid";
import oscarSad from "./../media/oscar_sad.png";

const AccountApi = require("./../api/accountApi");

interface IFriendListModalProps {
    friendships: IFriendshipDto[];
    friendshipsLoaded: any;
    clickPanel: any;
}

interface IFriendListModalState {
    showModal: boolean;
}

class FriendListModal extends Component<IFriendListModalProps, IFriendListModalState> {
    constructor(props: IFriendListModalProps) {
        super(props);
        this.state = {
            showModal: false
        };

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    componentDidMount() {
        AccountApi.getFriendships().then((friendshipCollectionDto: IFriendshipCollectionDto) =>
            this.props.friendshipsLoaded(friendshipCollectionDto.friendships)
        );
    }

    handleOpenModal() {
        this.setState({showModal: true});
    }

    handleCloseModal() {
        this.setState({showModal: false});
    }

    findEntriesMatchingEnum(requestedStatus: FriendshipInvitationStatus, friendships: IFriendshipDto[]): JSX.Element[] {
        return friendships
            .filter(friendship => friendship.friendshipInvitationStatus === requestedStatus)
            .map(friendship => <FriendPanel
                key={uuidv1()}
                friendshipStatus={requestedStatus}
                userName={friendship.name}
                userEmail={friendship.email}
                pictureUrl={friendship.pictureUrl}
                onAccept={() => {
                    AccountApi.acceptFriendRequest(friendship.friendshipId).then((friendshipCollectionDto: IFriendshipCollectionDto) => {
                        this.props.friendshipsLoaded(friendshipCollectionDto.friendships);
                    });
                }
                }
                onReject={() => {
                    AccountApi.declineFriendRequest(friendship.friendshipId).then((friendshipCollectionDto: IFriendshipCollectionDto) => {
                        this.props.friendshipsLoaded(friendshipCollectionDto.friendships);
                    });
                }}
            />);
    }

    render() {
        let modalContent: React.ReactFragment[] = [];
        if (this.props.friendships.length > 0) {
            const friendRequests: JSX.Element[] = this.findEntriesMatchingEnum(FriendshipInvitationStatus.friend_request, this.props.friendships);
            const sentRequests: JSX.Element[] = this.findEntriesMatchingEnum(FriendshipInvitationStatus.invitation_sent, this.props.friendships);
            const friends: JSX.Element[] = this.findEntriesMatchingEnum(FriendshipInvitationStatus.friends, this.props.friendships);

            modalContent.push(renderGroup(friendRequests, "Venneforespørsler"));
            modalContent.push(renderGroup(sentRequests, "Forespørsler du har sendt"));
            modalContent.push(renderGroup(friends, "Venner"));
            modalContent.push(
                <div style={{textAlign: "center"}} key={uuidv1()}>
                    <StyledButton
                        buttonStyle={BUTTONSTYLE_CLOSE_WINDOW}
                        onClickCB={this.handleCloseModal}
                    />
                </div>
            )
        } else {
            modalContent.push(
                <div style={{textAlign: "center"}} key={uuidv1()}>
                    <h1>Ingenting å vise!</h1>
                    <img src={oscarSad} height="150" alt="oscar_sad"/>
                    <p>Du har ikke sendt eller mottatt noen venneforespørsler akkurat nå.</p>
                    <StyledButton
                        buttonStyle={BUTTONSTYLE_CLOSE_WINDOW}
                        onClickCB={this.handleCloseModal}
                    />
                </div>
            )
        }

        return (
            <div>
                <div onClick={this.handleOpenModal}>
                    {this.props.clickPanel}
                </div>
                <ReactModal
                    closeTimeoutMS={300}
                    isOpen={this.state.showModal}
                    contentLabel="onRequestClose"
                    onRequestClose={this.handleCloseModal}
                    className="ModalContent FriendListModalContent"
                    overlayClassName="ModalOverlay"
                >
                    <div>
                        {modalContent}
                    </div>
                </ReactModal>
            </div>
        );
    }
}

const renderGroup = (itemsToRender: JSX.Element[], header: string): React.ReactFragment => {
    if (itemsToRender.length > 0) {
        const numberOfItems: number = itemsToRender.length;
        return (
            <React.Fragment key={uuidv1()}>
                <h1>{`${header} (${numberOfItems})`}</h1>
                <div style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyItems: "space-between",
                    justifyContent: "space-between"
                }}>
                    {itemsToRender}
                </div>
            </React.Fragment>
        )
    } else {
        return <React.Fragment key={uuidv1()}/>
    }
};

const mapStateToProps = (state: any) => ({
    friendships: state.accountState.friendships,
});

const mapDispatchToProps = (dispatch: any) => ({
    friendshipsLoaded: (friendships: IFriendshipDto[]) => dispatch(friendshipsLoaded(friendships)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FriendListModal);
