import React from "react";

interface IStyledSelectProps {
    hasError: boolean;
    onChangeCB: (value: string) => void;
    list: any;
    value: any;
}

export const StyledSelect = ({value, list, onChangeCB, hasError}: IStyledSelectProps) => {
    let className;
    if (hasError === true) {
        className = "styledSelectSheet inputError";
    } else {
        className = "styledSelectSheet inputOK";
    }
    return (
        <select
            className={className}
            value={value}
            onChange={event => onChangeCB(event.target.value)}
        >
            {list}
        </select>
    );
};
