import React from "react";

import oscarQuestionMark from "./media/oscar_questionmark.png";

export const displayBackendError = () => {
    return (
        <div style={{textAlign: "center"}}>
            <h1>Whups - her gikk noe galt!</h1>
            <img src={oscarQuestionMark} height="300" alt="oscar_questionmark"/>
            <h2>Prøv igjen om litt så er det (forhåpentligvis) i orden igjen.</h2>
        </div>
    );
};