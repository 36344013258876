import {ICategory} from "../searchRecipe/recipeCategory";

export const GetCategoryDescription = (Value: string, Enum: ICategory[]) => {
    if (Enum === undefined) {
        return Value;
    }

    let description;

    Enum.forEach((category: ICategory) => {
        if (category.Value === Value) {
            description = category.Description;
        }
    });

    if (description === undefined) {
        throw new Error("Unable to resolve category description for value = " + Value);
    } else {
        return description;
    }
};

export const unitHasNoAmount = (unit: string) => {
    return unit === "to_taste";
};
