import {FRIENDSHIPS_LOADED} from "./actions";

export const accountState = (state: any, action: any) => {
    if (state === undefined) {
        return {
            friendships: [],
            changeAccountInfoPopupOpen: false,
            friendListPopupOpen: false
        };
    }

    switch (action.type) {
        case FRIENDSHIPS_LOADED:
            return Object.assign({}, state, {friendships: action.friendships});

        default:
            return state;
    }
};
