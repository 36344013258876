import {CLEAR_CATEGORY_FILTER, LOAD_RECIPES, UPDATE_CATEGORY_FILTER, UPDATE_SEARCH_STRING} from "./actions";

export const searchRecipeState = (state: any, action: any) => {
    if (state === undefined) {
        return {
            categoryFilter: [],
            searchString: "",
            recipes: []
        };
    }

    let newCategoryFilter;

    switch (action.type) {
        case LOAD_RECIPES:
            return Object.assign({}, state, {recipes: action.recipes});

        case UPDATE_SEARCH_STRING:
            return Object.assign({}, state, {searchString: action.searchString});

        case UPDATE_CATEGORY_FILTER:
            if (state.categoryFilter.includes(action.category)) {
                newCategoryFilter = state.categoryFilter.filter(
                    (candidate: string) => candidate !== action.category
                );
            } else {
                newCategoryFilter = [...state.categoryFilter, action.category];
            }
            return Object.assign({}, state, {categoryFilter: newCategoryFilter});

        case CLEAR_CATEGORY_FILTER:
            return Object.assign({}, state, {categoryFilter: []});

        default:
            return state;
    }
};
