import {connect} from "react-redux";
import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import * as LABELS from "./labels";
import {GetCategoryDescription, unitHasNoAmount} from "../common/enumHelpers";
import {StyledButton} from "../components/styledButton";
import {IIngredientGroupDto, IRecipeDto} from "./types";

const RecipeApi = require("../api/recipeApi");

interface IShowRecipeProps {
    match: any;
    history: any;
    location: any;
    getUnitLabel: (unit: string) => {};
    getCategoryLabel: (category: string) => string;
}

interface IShowRecipeState {
    recipeDto: IRecipeDto;
}

class ShowRecipe extends Component<IShowRecipeProps, IShowRecipeState> {
    constructor(props: IShowRecipeProps) {
        super(props);
        this.state = {
            recipeDto: {
                id: "",
                categories: [],
                ingredientGroups: [],
                instructions: "",
                name: ""
            }
        };
    }

    componentDidMount() {
        const recipeId = this.props.location.pathname.split("/").pop();
        if (recipeId.id !== null) {
            RecipeApi.getRecipeById(recipeId).then((recipeFromDatabase: IRecipeDto) => {
                this.setState({recipeDto: recipeFromDatabase});
            });
        }
    }

    render() {
        const recipeDto = this.state.recipeDto;
        if (recipeDto !== null) {
            const ingredientGroupDisplay = recipeDto.ingredientGroups.map(
                (ingredientGroup: IIngredientGroupDto, index) => {
                    const ingredientListDisplay = ingredientGroup.ingredients.map(
                        (ingredient, index) => {
                            const unitLabel = this.props.getUnitLabel(ingredient.unit);

                            if (unitHasNoAmount(ingredient.unit)) {
                                return (
                                    <tr key={index}>
                                        <td colSpan={2}>{unitLabel}</td>
                                        <td>{ingredient.name}</td>
                                    </tr>
                                );
                            }
                            return (
                                <tr key={index}>
                                    <td>{ingredient.amount}</td>
                                    <td>{unitLabel}</td>
                                    <td>{ingredient.name}</td>
                                </tr>
                            );
                        },
                        this
                    );

                    return (
                        <tbody key={index}>
                        <tr>
                            <th colSpan={3}>
                                <h2>{ingredientGroup.name}</h2>
                            </th>
                        </tr>
                        {ingredientListDisplay}
                        </tbody>
                    );
                },
                this
            );

            const categoryDisplayCell: string[] = [];
            this.state.recipeDto.categories.forEach(category =>
                categoryDisplayCell.push(this.props.getCategoryLabel(category))
            );

            return (
                <div className="show-recipe">
                    <div className="show-recipe-top">
                        <h1>{recipeDto.name}</h1>
                        <p>{categoryDisplayCell.join(", ")}</p>
                        <StyledButton
                            icon={faPencilAlt}
                            onClickCB={() =>
                                this.props.history.push(`/oppskrifter/endre/${recipeDto.id}`)
                            }
                            text={LABELS.BUTTON_CHANGE_RECIPE}
                        />
                    </div>
                    <div className="show-recipe-ingredients">
                        <table>{ingredientGroupDisplay}</table>
                    </div>
                    <div className="show-recipe-instructions">
                        <h2>{LABELS.HEADER_INSTRUCTIONS}</h2>
                        <p>{recipeDto.instructions}</p>
                    </div>
                </div>
            );
        }
        return null;
    }
}

const mapStateToProps = (state: any) => ({
    getUnitLabel: (unit: string) =>
        GetCategoryDescription(unit, state.commonState.UnitEnum),
    getCategoryLabel: (category: string) =>
        GetCategoryDescription(category, state.commonState.CategoryEnum)
});

export default withRouter(
    connect(
        mapStateToProps,
        null
    )(ShowRecipe)
);
