import ReactModal from "react-modal";
import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {faArrowRight, faBullhorn, faUnlink} from "@fortawesome/free-solid-svg-icons";
import {BUTTONSTYLE_CLOSE_WINDOW, StyledButton} from "../components/styledButton";
import {closeShareShoppingList, updateShoppingLists} from "./actions";
import {FindSharesByShoppingListId, SendShareShoppingList, SendUnshareShoppingList} from "./utils";
import {IShoppingListCollectionDto, IShoppingListDto} from "../shoppingList/types";
import {FriendshipStatus, IFriendshipDto} from "../account/types";
import uuidv1 from "uuid";
import oscarShare from "./../media/oscar_share.png";
import oscarSad from "./../media/oscar_sad.png";
import {Dispatch} from "redux";

const isShared = (friendshipId: string, currentShares: string[]) =>
    currentShares.includes(friendshipId);

export enum ModalOpenStatus {
    open,
    closed
}

interface IShareShoppingListProps {
    closePopup: any;
    dispatchUpdateShoppingLists: (shoppingLists: IShoppingListCollectionDto) => {};
    friendships: IFriendshipDto[];
    history: any;
    location: any;
    match: any;
    shareShoppingList: any;
    shoppingLists: IShoppingListDto[];
}

const ShareShoppingList = ({
                               closePopup,
                               dispatchUpdateShoppingLists,
                               friendships,
                               history,
                               shareShoppingList,
                               shoppingLists
                           }: IShareShoppingListProps) => {
    const currentShares = FindSharesByShoppingListId(
        shoppingLists,
        shareShoppingList.shoppingListId
    );

    const friendshipDisplay = friendships.map((friendship: IFriendshipDto) => {
        const listIsShared = isShared(friendship.friendshipId, currentShares);
        const shoppingListId = shareShoppingList.shoppingListId;

        if (friendship.friendshipStatus === FriendshipStatus.accepted) {
            return (
                <tr style={{textAlign: "left"}} key={uuidv1()}>
                    <td
                        style={{
                            paddingRight: "10px",
                            width: "130px",
                            border: "0px solid black"
                        }}
                    >
                        {friendship.name}
                    </td>
                    <td
                        style={{
                            paddingRight: "10px",
                            width: "60px",
                            border: "0px solid black"
                        }}
                    >
                        {listIsShared ? "Delt" : "Ikke delt"}
                    </td>
                    <td style={{border: "0px solid black"}}>
                        <StyledButton
                            icon={listIsShared ? faUnlink : faBullhorn}
                            text={listIsShared ? "Fjern" : "Del"}
                            onClickCB={() =>
                                listIsShared
                                    ? SendUnshareShoppingList(
                                    shoppingListId,
                                    friendship.friendshipId,
                                    dispatchUpdateShoppingLists
                                    )
                                    : SendShareShoppingList(
                                    shoppingListId,
                                    friendship.friendshipId,
                                    dispatchUpdateShoppingLists
                                    )
                            }
                        />
                    </td>
                </tr>
            );
        }
        return null;
    });

    return (
        <ReactModal
            closeTimeoutMS={300}
            isOpen={shareShoppingList.modalState === ModalOpenStatus.open}
            contentLabel="onRequestClose"
            onRequestClose={closePopup}
            className="ModalContent"
            overlayClassName="ModalOverlay"
        >
            <div style={{textAlign: "center"}}>
                {friendships.length > 0 ? (
                    <div>
                        <h1>Del handleliste</h1>
                        <img src={oscarShare} height="150" alt="oscar_share"/>
                        <table style={{paddingBottom: "20px"}}>
                            <tbody>
                            <tr style={{textAlign: "left"}}>
                                <th>Venn</th>
                                <th>Status</th>
                                <th/>
                            </tr>
                            {friendshipDisplay}
                            </tbody>
                        </table>
                        <StyledButton
                            buttonStyle={BUTTONSTYLE_CLOSE_WINDOW}
                            onClickCB={() => {
                                closePopup();
                            }}
                        />
                    </div>
                ) : (
                    <div>
                        <h1>Del handleliste</h1>
                        <img src={oscarSad} height="150" alt="oscar_sad"/>
                        <p>Du har ingen å dele handlelister med helt enda.</p>
                        <p>Legg til noen venner på Konto-siden, da vel!</p>
                        <StyledButton
                            text="Gå til Konto-siden"
                            icon={faArrowRight}
                            onClickCB={() => {
                                closePopup();
                                history.push("/konto");
                            }}
                        />
                        <StyledButton
                            buttonStyle={BUTTONSTYLE_CLOSE_WINDOW}
                            onClickCB={() => {
                                closePopup();
                            }}
                        />
                    </div>
                )}
            </div>
        </ReactModal>
    );
};

const mapStateToProps = (state: any) => ({
    shoppingLists: state.shoppingListMenuState.shoppingLists,
    friendships: state.accountState.friendships,
    shareShoppingList: state.shoppingListMenuState.shareShoppingList
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    closePopup: () => dispatch(closeShareShoppingList()),
    dispatchUpdateShoppingLists: (shoppingLists: IShoppingListCollectionDto) =>
        dispatch(updateShoppingLists(shoppingLists))
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ShareShoppingList)
);
