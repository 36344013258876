export const BUTTON_LABEL_ADD_INGREDIENT = "Legg til ingrediensgruppe";
export const BUTTON_LABEL_MOVE_INGREDIENT = "Flytt ingredienser";
export const BUTTON_LABEL_SAVE_RECIPE = "Lagre oppskrift";
export const BUTTON_LABEL_DELETE_RECIPE = "Slett oppskrift";

export const PLACEHOLDER_RECIPE_NAME = "Navn på oppskriften";
export const PLACEHOLDER_INGREDIENT_GROUP_NAME =
    "Ingredienskategori, f.eks. fyll, bunn...";
export const PLACEHOLDER_INGREDIENT_NAME = "Ingrediens";
export const PLACEHOLDER_INGREDIENT_AMOUNT = "Mengde";
export const PLACEHOLDER_INSTRUCTIONS = "Instruksjoner";
export const PLACEHOLDER_UNIT = "Enhet";
export const STATUS_DELETE_ERROR = "Feil under sletting av oppskrift.";

export const RECIPE_CHANGES_PENDING = "RECIPE_CHANGES_PENDING";
export const RECIPE_SAVED_OK = "RECIPE_SAVED_OK";
export const RECIPE_SAVE_FAILED = "RECIPE_SAVE_FAILED";
