import React from "react";
import {connect} from "react-redux";
import * as LABELS from "./labels";
import {changeRecipeInstructions} from "./actions";
import {Dispatch} from "redux";

interface IRecipeInstructionsProps {
    instructions: string;
    onInstructionsChange: (instructions: string) => void;
    showError: () => boolean;
}

const RecipeInstructions = ({
                                instructions,
                                onInstructionsChange,
                                showError
                            }: IRecipeInstructionsProps) => (
    <textarea
        className={showError() && instructions === "" ? "inputError" : "inputOK"}
        value={instructions}
        onChange={event => onInstructionsChange(event.target.value)}
        placeholder={LABELS.PLACEHOLDER_INSTRUCTIONS}
    />
);

const mapStateToProps = (state: any) => ({
    instructions: state.changeRecipeState.instructions,
    showError: () =>
        state.changeRecipeState.stateStatus === LABELS.RECIPE_SAVE_FAILED
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onInstructionsChange: (updatedInstructions: string) =>
        dispatch(changeRecipeInstructions(updatedInstructions))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RecipeInstructions);
