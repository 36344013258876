import {resolveAccessToken} from "./accessToken";
import {getBackendURI} from "../backendconfig";
import {handleHttpStatus} from "./statusHandler";
import {Dispatch} from "redux";
import {refreshShoppingLists, updateShoppinglistMenuBackendStatus} from "../shoppingListMenu/actions";
import {BackendCallStatusEnum, DataActionEnum} from "../types";
import {IShoppingList, IShoppingListCollectionDto, IShoppingListDto, IShoppingListItemDto} from "../shoppingList/types";
import {shoppingListLoaded, updateShoppinglistBackendStatus} from "../shoppingList/actions";

export const saveShoppingList = (shoppinglist: any) =>
    resolveAccessToken().then((accessToken: string) =>
        fetch(`${getBackendURI()}/api/shoppinglist/`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: accessToken
            },
            body: JSON.stringify(shoppinglist)
        })
    );

export const shareShoppingList = (shoppingListId: string, friendshipId: string) =>
    resolveAccessToken()
        .then((accessToken: string) =>
            fetch(
                `${getBackendURI()}/api/shoppinglist/${shoppingListId}/share/${friendshipId}`,
                {
                    method: "POST",
                    headers: {
                        Authorization: accessToken
                    }
                }
            )
        )
        .then((result: any) => handleHttpStatus(result));

export const unShareShoppingList = (shoppingListId: string, friendshipId: string) =>
    resolveAccessToken()
        .then((accessToken: string) =>
            fetch(
                `${getBackendURI()}/api/shoppinglist/${shoppingListId}/share/${friendshipId}`,
                {
                    method: "DELETE",
                    headers: {
                        Authorization: accessToken
                    }
                }
            )
        )
        .then((result: any) => handleHttpStatus(result));

export const loadShoppingListsAction = (dispatch: Dispatch) => {
    dispatch(updateShoppinglistMenuBackendStatus(BackendCallStatusEnum.CALL_IN_PROGRESS, DataActionEnum.FETCH_ALL));
    resolveAccessToken()
        .then((accessToken: string) =>
            fetch(`${getBackendURI()}/api/shoppinglist/`, {
                headers: {
                    Authorization: accessToken
                }
            })
        )
        .then((result: any) => handleHttpStatus(result))
        .then((value: IShoppingListCollectionDto) => dispatch(refreshShoppingLists(DataActionEnum.FETCH_ALL, value)))
        .catch(() => dispatch(updateShoppinglistMenuBackendStatus(BackendCallStatusEnum.CALL_FAILED, DataActionEnum.FETCH_ALL)));
};

export const deleteShoppingListAction = (dispatch: Dispatch, idToDelete: string) => {
    dispatch(updateShoppinglistMenuBackendStatus(BackendCallStatusEnum.CALL_IN_PROGRESS, DataActionEnum.DELETE));
    resolveAccessToken()
        .then((accessToken: string) =>
            fetch(`${getBackendURI()}/api/shoppinglist/${idToDelete}`, {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: accessToken
                },
                body: JSON.stringify({id: idToDelete})
            })
        )
        .then((result: any) => handleHttpStatus(result))
        .then((value: IShoppingListCollectionDto) => dispatch(refreshShoppingLists(DataActionEnum.DELETE, value)))
        .catch(() => dispatch(updateShoppinglistMenuBackendStatus(BackendCallStatusEnum.CALL_FAILED, DataActionEnum.DELETE)));
};

export const addNewShoppingListAction = (dispatch: Dispatch) => {
    const newShoppingList = {
        name: "Ny handleliste",
        id: "",
        shoppingListItems: []
    };

    dispatch(updateShoppinglistMenuBackendStatus(BackendCallStatusEnum.CALL_IN_PROGRESS, DataActionEnum.CREATE));

    resolveAccessToken()
        .then((accessToken: string) =>
            fetch(`${getBackendURI()}/api/shoppinglist/`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: accessToken
                },
                body: JSON.stringify(newShoppingList)
            })
        )
        .then((result: any) => handleHttpStatus(result))
        .then((value: IShoppingListCollectionDto) => dispatch(refreshShoppingLists(DataActionEnum.CREATE, value)))
        .catch(() => dispatch(updateShoppinglistMenuBackendStatus(BackendCallStatusEnum.CALL_FAILED, DataActionEnum.CREATE)));
};

export const getShoppingListAction = (dispatch: Dispatch, shoppinglistId: string) => {
    dispatch(updateShoppinglistBackendStatus(BackendCallStatusEnum.CALL_IN_PROGRESS, DataActionEnum.FETCH));
    resolveAccessToken()
        .then((accessToken: string) =>
            fetch(`${getBackendURI()}/api/shoppinglist/${shoppinglistId}`, {
                headers: {
                    Authorization: accessToken
                }
            })
        )
        .then((result: any) => handleHttpStatus(result))
        .then((shoppingList: IShoppingListDto) => {
            const shoppingListItemsMap = new Map();
            shoppingList.shoppingListItems.forEach((shoppingListItem: IShoppingListItemDto) => {
                shoppingListItemsMap.set(shoppingListItem.uuid, {
                    description: shoppingListItem.description,
                    checked: shoppingListItem.checked
                });
            });

            dispatch(shoppingListLoaded({
                items: shoppingListItemsMap,
                id: shoppingList.id,
                name: shoppingList.name
            }));
        })
        .catch(() => dispatch(updateShoppinglistBackendStatus(BackendCallStatusEnum.CALL_FAILED, DataActionEnum.FETCH)));
};