import React, {Component} from "react";
import ReactModal from 'react-modal';
import {
    faCheckCircle,
    faExclamationTriangle,
    faInfoCircle,
    faPaperPlane,
    IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import {validate} from "email-validator";
import {IFriendshipRequestResponseDto, SeverityEnum} from "./types";
import {BUTTONSTYLE_CLOSE_WINDOW, StyledButton} from "../components/styledButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import oscarShare from "./../media/oscar_share.png";

const AccountApi = require("./../api/accountApi");

const initialState = {
    showModal: false,
    email: "",
    response: {message: "", severity: undefined}
};

class AddFriendModal extends Component<IAddFriendModalProps, IAddFriendModalState> {

    constructor(props: IAddFriendModalProps) {
        super(props);
        this.state = initialState;

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    handleOpenModal() {
        this.setState({showModal: true});
    }

    handleCloseModal() {
        this.setState(initialState);
    }

    updateInput = (email: string) => {
        this.setState({email, response: {message: "", severity: undefined}});
    };

    updateResponse = (message: string, severity: SeverityEnum) => {
        if (severity === SeverityEnum.ok) {
            this.setState({email: "", response: {message, severity}});
        } else {
            this.setState({response: {message, severity}});
        }
    };

    render() {
        const response = generateResponseMessage(
            this.state.response.message,
            this.state.response.severity
        );

        return (
            <div>
                <div onClick={this.handleOpenModal}>
                    {this.props.clickPanel}
                </div>

                <ReactModal
                    closeTimeoutMS={300}
                    isOpen={this.state.showModal}
                    contentLabel="onRequestClose"
                    onRequestClose={this.handleCloseModal}
                    className="ModalContent"
                    overlayClassName="ModalOverlay"
                >

                    <div style={{textAlign: "center"}}>
                        <h1>Send venneforespørsel</h1>
                        <img src={oscarShare} height="150" alt="oscar_share"/>
                        <div>
                            <p>
                                Her kan du sende en venneforespørsel til en annen bruker av Smaksbanken. Når mottakeren
                                har akseptert forespørselen kan dere dele innhold med hverandre!
                            </p>
                        </div>
                        <div>
                            <input
                                onKeyPress={event => {
                                    if (event.key === "Enter") {
                                        sendFriendRequest(this.state.email, this.updateResponse)
                                    }
                                }}
                                style={{width: "80%"}}
                                value={this.state.email}
                                onChange={event => this.updateInput(event.target.value)}
                                placeholder={"Skriv e-posten til den du vil invitere her"}
                            />
                        </div>
                        <div style={{height: "2em", padding: "1em"}}>
                            {response}
                        </div>
                        <div>
                            <StyledButton
                                icon={faPaperPlane}
                                onClickCB={() => sendFriendRequest(this.state.email, this.updateResponse)
                                }
                                text="Send invitasjon"
                            />
                            <StyledButton
                                buttonStyle={BUTTONSTYLE_CLOSE_WINDOW}
                                onClickCB={this.handleCloseModal}
                            />
                        </div>
                    </div>
                </ReactModal>
            </div>
        );
    }
}

interface IAddFriendModalProps {
    clickPanel: any;
}

interface IAddFriendModalState {
    showModal: boolean;
    response: IResponse;
    email: string;
}

interface IResponse {
    message: string;
    severity: SeverityEnum | undefined;
}

const sendFriendRequest = (email: string, updateResponse: (message: string, severity: SeverityEnum) => void) => {
    if (validate(email)) {
        AccountApi.sendFriendRequest(email).then((result: IFriendshipRequestResponseDto) => {
            updateResponse(result.userMessage, result.severity);
        });
    } else {
        updateResponse("Ugyldig e-post-adresse", SeverityEnum.warning);
    }
};

const generateResponseMessage = (message: string, severity: SeverityEnum | undefined) => {
    let icon: IconDefinition | undefined;
    switch (severity) {
        case SeverityEnum.ok:
            icon = faCheckCircle;
            break;
        case SeverityEnum.warning:
            icon = faExclamationTriangle;
            break;
        case SeverityEnum.info:
            icon = faInfoCircle;
            break;
        default:
            break;
    }
    if (icon !== undefined) {
        return (
            <div>
                <FontAwesomeIcon
                    icon={icon}
                    style={{paddingRight: "10px"}}
                    aria-hidden="true"
                />
                {message}
            </div>
        );
    } else {
        return <></>;
    }
};

export default AddFriendModal;