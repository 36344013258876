import {connect} from "react-redux";
import React from "react";
import {changeRecipeCategory} from "./actions";
import {StyledCheckbox} from "../components/styledCheckbox";
import {Dispatch} from "redux";
import {ICategory} from "../searchRecipe/recipeCategory";

interface ICategoryCheckboxProps {
    onChangeRecipeCategory: (category: string) => void;
    categories: string[];
    category: ICategory;
}

const CategoryCheckbox = ({onChangeRecipeCategory, categories, category}: ICategoryCheckboxProps) => (
    <StyledCheckbox
        isChecked={categories.includes(category.Value)}
        onChange={() => onChangeRecipeCategory(category.Value)}
        value={category.Value}
        textlabel={category.Description}
    />
);

const mapStateToProps = (state: any) => ({
    categories: state.changeRecipeState.categories
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onChangeRecipeCategory: (category: string) => dispatch(changeRecipeCategory(category))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CategoryCheckbox);
