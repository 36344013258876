import {
    CLOSE_SHARE_SHOPPING_LIST,
    OPEN_SHARE_SHOPPIG_LIST_MODAL,
    REFRESH_SHOPPING_LISTS,
    TOGGLE_DELETE_MODE,
    TOGGLE_SHARE_MODE,
    UPDATE_SHOPPINGLIST_MENU_BACKEND_STATUS,
    UPDATE_SHOPPING_LISTS
} from "./actions";
import {ModalOpenStatus} from "./shareShoppingList";
import {IShoppingListDto} from "../shoppingList/types";
import {BackendCallStatusEnum, DataActionEnum, MenuDisplayEnum} from "../types";

interface IShoppingListMenuState {
    shoppingLists: IShoppingListDto[];
    sharedShoppingLists: Map<string, IShoppingListDto[]>;
    shareShoppingList: IShareShoppingListModal;
    fetchShoppingListsStatus: BackendCallStatusEnum;
    deleteShoppingListStatus: BackendCallStatusEnum;
    createShoppingListStatus: BackendCallStatusEnum;
    menuMode: MenuDisplayEnum;
}

interface IShareShoppingListModal {
    shoppingListId: string;
    modalState: ModalOpenStatus;
}

export const shoppingListMenuState = (state: IShoppingListMenuState | undefined, action: any): IShoppingListMenuState => {
    if (state === undefined) {
        return {
            shoppingLists: [],
            sharedShoppingLists: new Map<string, IShoppingListDto[]>(),
            shareShoppingList: {
                shoppingListId: "",
                modalState: ModalOpenStatus.closed
            },
            fetchShoppingListsStatus: BackendCallStatusEnum.CALL_IN_PROGRESS,
            deleteShoppingListStatus: BackendCallStatusEnum.CALL_SUCCESSFUL,
            createShoppingListStatus: BackendCallStatusEnum.CALL_SUCCESSFUL,
            menuMode: MenuDisplayEnum.NORMAL_MODE
        };
    }

    switch (action.type) {
        case UPDATE_SHOPPING_LISTS:
            return Object.assign({}, state, {
                shoppingLists: action.shoppinglists.ownShoppingLists,
                sharedShoppingLists: action.shoppinglists.sharedShoppingLists,
            });

        case CLOSE_SHARE_SHOPPING_LIST:
            return Object.assign({}, state, {
                shareShoppingList: {
                    shoppingListId: "",
                    modalState: ModalOpenStatus.closed
                }
            });

        case OPEN_SHARE_SHOPPIG_LIST_MODAL:
            return Object.assign({}, state, {
                shareShoppingList: {
                    shoppingListId: action.shoppingListId,
                    modalState: ModalOpenStatus.open
                }
            });

        case TOGGLE_DELETE_MODE:
            return {
                ...state,
                menuMode: state.menuMode === MenuDisplayEnum.DELETE_MODE ? MenuDisplayEnum.NORMAL_MODE : MenuDisplayEnum.DELETE_MODE
            };

        case TOGGLE_SHARE_MODE:
            return {
                ...state,
                menuMode: state.menuMode === MenuDisplayEnum.SHARE_MODE ? MenuDisplayEnum.NORMAL_MODE : MenuDisplayEnum.SHARE_MODE
            };

        case REFRESH_SHOPPING_LISTS:
            let updatedState = {
                ...state,
                shoppingLists: action.shoppingLists.ownShoppingLists,
                sharedShoppingLists: action.shoppingLists.sharedShoppingLists
            };

            switch (action.dataAction) {
                case DataActionEnum.FETCH_ALL:
                    updatedState.fetchShoppingListsStatus = BackendCallStatusEnum.CALL_SUCCESSFUL;
                    return updatedState;
                case DataActionEnum.CREATE:
                    updatedState.createShoppingListStatus = BackendCallStatusEnum.CALL_SUCCESSFUL;
                    return updatedState;
                case DataActionEnum.DELETE:
                    if(action.shoppingLists.ownShoppingLists.length === 0){
                        updatedState.menuMode = MenuDisplayEnum.NORMAL_MODE;
                    }
                    updatedState.deleteShoppingListStatus = BackendCallStatusEnum.CALL_SUCCESSFUL;
                    return updatedState;
                default:
                    return state;
            }

        case UPDATE_SHOPPINGLIST_MENU_BACKEND_STATUS:
            switch (action.dataAction) {
                case DataActionEnum.CREATE:
                    return {
                        ...state,
                        createShoppingListStatus: action.status
                    };
                case DataActionEnum.DELETE:
                    return {
                        ...state,
                        deleteShoppingListStatus: action.status
                    };
                case DataActionEnum.FETCH_ALL:
                    return {
                        ...state,
                        fetchShoppingListsStatus: action.status
                    };
                default:
                    return state;

            }

        default:
            return state;
    }
};
