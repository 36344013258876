export interface IFriendshipCollectionDto {
    friendships: IFriendshipDto[];
}

export interface IFriendshipDto {
    email: string;
    friendshipId: string;
    name: string;
    pictureUrl: string;
    friendshipInvitationStatus: FriendshipInvitationStatus;
    friendshipStatus: FriendshipStatus;
}

export interface IFriendshipRequestResponseDto {
    friendships: IFriendshipDto[];
    userMessage: string;
    severity: SeverityEnum;
}

export enum FriendshipInvitationStatus {
    friends = 'friends',
    invitation_sent = 'invitation_sent',
    friend_request = 'friend_request'
}

export enum FriendshipStatus {
    accepted = 'accepted',
    pending = 'pending'
}

export enum SeverityEnum {
    ok = 'ok',
    info = 'info',
    warning = 'warning'
}