import {ICategory, IUnit} from "./searchRecipe/recipeCategory";

interface ICommonState {
    CategoryEnum: ICategory[];
    UnitEnum: IUnit[];
}

export const commonState = (state: ICommonState | undefined, action: any): ICommonState | undefined => {
    if (state === undefined) {
        return {
            CategoryEnum: [
                {
                    Value: "dinner",
                    Description: "Middagsretter"
                },
                {
                    Value: "breakfast",
                    Description: "Frokost"
                },
                {
                    Value: "appetisers",
                    Description: "Småretter/Forretter"
                },
                {
                    Value: "soups",
                    Description: "Supper"
                },
                {
                    Value: "stews",
                    Description: "Gryter"
                },
                {
                    Value: "vegan",
                    Description: "Vegetarisk"
                },
                {
                    Value: "sides",
                    Description: "Tilbehør/Sauser"
                },
                {
                    Value: "bakedGoods",
                    Description: "Bakst"
                },
                {
                    Value: "desserts",
                    Description: "Desserter"
                },
                {
                    Value: "cake",
                    Description: "Kaker/Paier/Kjeks"
                },
                {
                    Value: "traditional",
                    Description: "Jul/Høytid/Tradisjonsmat"
                },
                {
                    Value: "drinks",
                    Description: "Drikke"
                },
                {
                    Value: "fish",
                    Description: "Fisk"
                },
                {
                    Value: "meat",
                    Description: "Kjøtt"
                },
                {
                    Value: "chicken",
                    Description: "Kylling"
                },
                {
                    Value: "snacks",
                    Description: "Snacks/Godteri/Konfekt"
                }
            ],
            UnitEnum: [
                {
                    Value: "dl",
                    Description: "dl"
                },
                {
                    Value: "l",
                    Description: "l"
                },
                {
                    Value: "kg",
                    Description: "kg"
                },
                {
                    Value: "g",
                    Description: "g"
                },
                {
                    Value: "unit",
                    Description: "stk"
                },
                {
                    Value: "box",
                    Description: "boks"
                },
                {
                    Value: "pack",
                    Description: "pakke"
                },
                {
                    Value: "handful",
                    Description: "never"
                },
                {
                    Value: "clove",
                    Description: "fedd"
                },
                {
                    Value: "to_taste",
                    Description: "smak til med"
                },
                {
                    Value: "tablespoon",
                    Description: "ss"
                },
                {
                    Value: "teaspoon",
                    Description: "ts"
                }
            ]
        };
    }

  return state;
};
