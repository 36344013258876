import {
    ADD_ITEM_TO_SHOPPING_LIST,
    CHECK_SHOPPING_LIST_ITEM,
    DELETE_SHOPPING_LIST_ITEM,
    SHOPPING_LIST_LOADED,
    UPDATE_SHOPPING_LIST_ITEM,
    UPDATE_SHOPPING_LIST_NAME,
    UPDATE_SHOPPINGLIST_BACKEND_STATUS
} from "./actions";
import {
    IShoppingList,
    IShoppingListItem,
    IShoppingListItemDto,
    IShoppingListReduxState,
    tShoppingListItemMap
} from "./types";
import {BackendCallStatusEnum, DataActionEnum} from "../types";

const ShoppingListApi = require("../api/shoppingListApi");

const initialState: IShoppingListReduxState = {
    isFetching: BackendCallStatusEnum.CALL_IN_PROGRESS,
    isUpdating: BackendCallStatusEnum.CALL_SUCCESSFUL,
    suggestions: [],
    shoppingList: {
        items: new Map(),
        id: "",
        name: ""
    }
};

export const shoppingListState = (state: IShoppingListReduxState | undefined, action: any) => {
    let newState;
    if (state === undefined) {
        return initialState;
    }

    switch (action.type) {
        case ADD_ITEM_TO_SHOPPING_LIST:
            newState = {
                ...state, shoppingList: addItemToShoppingList(
                    action.newUuid,
                    action.newDescription,
                    state.shoppingList
                )
            };
            storeShoppinglistOnServer(newState);
            return newState;

        case CHECK_SHOPPING_LIST_ITEM:
            newState = {
                ...state, shoppingList: checkShoppingListItem(action.uuid, state.shoppingList)
            };
            storeShoppinglistOnServer(newState);
            return newState;

        case DELETE_SHOPPING_LIST_ITEM:
            newState = {
                ...state, shoppingList: deleteShoppingListItem(
                    action.uuid,
                    state.shoppingList
                )
            };
            storeShoppinglistOnServer(newState);
            return newState;

        case SHOPPING_LIST_LOADED:
            return {
                ...state,
                shoppingList: action.shoppingList,
                isFetching: BackendCallStatusEnum.CALL_SUCCESSFUL
            };

        case UPDATE_SHOPPING_LIST_ITEM:
            newState = {
                ...state, shoppingList: editShoppingListItem(
                    action.uuid,
                    state.shoppingList,
                    action.updatedDescription
                )
            };
            storeShoppinglistOnServer(newState);
            return newState;

        case UPDATE_SHOPPING_LIST_NAME:
            newState = {
                ...state, shoppingList: editShoppingListName(
                    action.newName,
                    state.shoppingList
                )
            };
            storeShoppinglistOnServer(newState);
            return newState;
        case UPDATE_SHOPPINGLIST_BACKEND_STATUS:
            if (action.dataAction === DataActionEnum.FETCH) {
                return {
                    ...state,
                    isFetching: action.status
                }
            } else if (action.dataAction === DataActionEnum.UPDATE) {
                return {
                    ...state,
                    isUpdating: action.status
                }
            } else {
                return state;
            }

        default:
            return state;
    }
};

const addItemToShoppingList = (newUuid: string, newDescription: string, currentShoppingList: IShoppingList): IShoppingList => {
    const updatedMap: tShoppingListItemMap = new Map();
    updatedMap.set(newUuid, {
        description: newDescription,
        checked: false
    });

    return {...currentShoppingList, items: new Map([...updatedMap, ...currentShoppingList.items])}
};

const checkShoppingListItem = (uuid: string, currentShoppingList: IShoppingList): IShoppingList => {
    const updatedItems: tShoppingListItemMap = new Map(currentShoppingList.items);
    let shoppingListItem: IShoppingListItem | undefined = updatedItems.get(uuid);
    if (shoppingListItem !== undefined) {
        shoppingListItem.checked = !shoppingListItem.checked;
        updatedItems.set(uuid, shoppingListItem);
        return {...currentShoppingList, items: new Map([...updatedItems, ...currentShoppingList.items])}
    }
    return currentShoppingList;
};

const deleteShoppingListItem = (uuid: string, currentShoppingList: IShoppingList): IShoppingList => {
    const updatedItems = new Map(currentShoppingList.items);
    updatedItems.delete(uuid);
    return {...currentShoppingList, items: updatedItems}
};

const editShoppingListName = (updatedName: string, currentShoppingList: IShoppingList) => {
    return {...currentShoppingList, name: updatedName}
};

const editShoppingListItem = (uuid: string, currentShoppingList: IShoppingList, updatedDescription: string): IShoppingList => {
    const updatedItems = new Map(currentShoppingList.items);
    let updatedItem: IShoppingListItem | undefined = updatedItems.get(uuid);
    if (updatedItem !== undefined) {
        updatedItem.description = updatedDescription;
        updatedItems.set(uuid, updatedItem);
        return {...currentShoppingList, items: new Map([...updatedItems, ...currentShoppingList.items])}
    } else {
        return currentShoppingList;
    }
};

const storeShoppinglistOnServer = (state: IShoppingListReduxState) => {
    const shoppingListItemArray: IShoppingListItemDto[] = [];
    state.shoppingList.items.forEach((shoppingListItem: IShoppingListItem, uuid: string) => {
        shoppingListItemArray.push({
            description: shoppingListItem.description,
            checked: shoppingListItem.checked,
            uuid: uuid
        });
    });

    ShoppingListApi.saveShoppingList({
        id: state.shoppingList.id,
        name: state.shoppingList.name,
        shoppingListItems: shoppingListItemArray
    });
};
