const dev = {
  BACKEND_HOST: "http://localhost:8080"
};

const prod = {
  BACKEND_HOST: "https://smaksbankenbackend-aas-dot-smaksbanken-200509.appspot.com"
};

const conditionalConfig =
  process.env.REACT_APP_STAGE === "production" ? prod : dev;

export const config = {
  ...conditionalConfig
};
