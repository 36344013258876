import {connect} from "react-redux";
import React from "react";
import CategoryCheckbox from "./categoryCheckbox";

import uuidv1 from "uuid";
import {ICategory} from "../searchRecipe/recipeCategory";

interface IRecipeCategoriesProps {
    categoryEnum: ICategory[]
}

const RecipeCategories = ({categoryEnum}: IRecipeCategoriesProps) => {
    const groupSize = 2;
    const groups = categoryEnum
        .map((item, index) =>
            index % groupSize === 0
                ? categoryEnum.slice(index, index + groupSize)
                : null
        )
        .filter(item => item);

    const tableRows = groups.map(group => {
        if (group !== null) {
            const tableCells = group.map(
                category => (
                    <td key={uuidv1()}>
                        <CategoryCheckbox category={category}/>
                    </td>
                )
            );

            return <tr key={uuidv1()}>{tableCells}</tr>;
        }
    });

    return (
        <table>
            <tbody>{tableRows}</tbody>
        </table>
    );
};

const mapStateToProps = (state: any) => ({
    categoryEnum: state.commonState.CategoryEnum
});

export default connect(
    mapStateToProps,
    null
)(RecipeCategories);
