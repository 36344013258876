import React, {Component} from "react";
import {GoogleLogin} from "react-google-login";
import {withRouter} from "react-router-dom";
import oscarSmile from "./../media/oscar_smile.png";
import {LoginStatusEnum} from "../types";
import {getClientId} from "../commonProperties";
import {BeatLoader} from "react-spinners";

const authenticationService = require("./authenticationService");

interface ILoginPageProps {
    changeLoginStatus: (loginStatus: LoginStatusEnum) => void;
    loginStatus: LoginStatusEnum;
    history: any;
    match: any;
    location: any;
}

class LoginPage extends Component<ILoginPageProps> {
    loginSuccessful(googleResponse: any) {
        authenticationService
            .authenticateUser(googleResponse.tokenId)
            .then((result: any) => {
                authenticationService.setSignedInUser(
                    result.displayName,
                    result.subject,
                    result.refreshToken
                );
                this.props.changeLoginStatus(LoginStatusEnum.SIGNED_IN);
                this.props.history.push("/handlelister");
            });
    }

    render() {
        return (
            <div className="login-page">
                <div>
                    <h1>Velkommen til Smaksbanken</h1>
                    <div style={{display: "grid"}}>
                        <div style={{gridArea: "1/1/2/2"}}>
                            <img src={oscarSmile} height="150" alt="oscar_working"/>
                        </div>
                        <div style={{gridArea: "1/1/2/2", alignSelf:"center"}}>
                            <BeatLoader size={30} color="#000"
                                        loading={this.props.loginStatus === LoginStatusEnum.SIGNIN_IN_PROGRESS}/>

                        </div>
                    </div>
                    <div>
                        <GoogleLogin
                            onRequest={() => {
                                this.props.changeLoginStatus(LoginStatusEnum.SIGNIN_IN_PROGRESS);
                            }}
                            buttonText={"Logg inn med Google"}
                            clientId={getClientId()}
                            onSuccess={response => this.loginSuccessful(response)}
                            onFailure={() => {
                                this.props.changeLoginStatus(LoginStatusEnum.SIGNIN_FAILED);
                            }}
                        />
                    </div>
                    <p>Smaksbanken er din egen digitale oppskriftsbok og handleliste.</p>
                    <p>Logg inn og kom i gang i dag!</p>
                </div>
            </div>
        );
    }
}

export default withRouter(LoginPage);
