import {isAfter, parse, subSeconds} from "date-fns";
import {getBackendURI} from "../backendconfig";
import {
    getAccessToken,
    getAccessTokenTimeout,
    getRefreshToken,
    signOutUser,
    updateAccessToken
} from "../login/authenticationService";

const formatBearerToken = (token: string) => `Bearer ${token}`;

export const resolveAccessToken = async () => {
    const accessTokenTimeout = parse(getAccessTokenTimeout());
    const accessToken = getAccessToken();
    const refreshToken = getRefreshToken();
    const now = new Date();

    if (
        accessToken !== null &&
        accessTokenTimeout !== null &&
        !isAfter(now, subSeconds(accessTokenTimeout, 5))
    ) {
        return formatBearerToken(accessToken);
    }
    if (refreshToken !== null) {
        try {
            const response = await fetch(`${getBackendURI()}/action/token`, {
                body: JSON.stringify({refreshToken}),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                method: "POST"
            });
            if (response.status === 200) {
                const json = await response.json();
                updateAccessToken(
                    json.accessToken,
                    json.refreshToken,
                    json.secondsUntilAccessTokenTimeout
                );
                return formatBearerToken(json.accessToken);
            } else if(response.status === 401){
                signOutUser();
            }
        } catch (err) {
            return Promise.reject(err);
        }

    } else {
        signOutUser();
        return "";
    }
    return "";
};
