import {connect} from "react-redux";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faArrowUp} from "@fortawesome/free-solid-svg-icons";
import {faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import UnitSelection from "./unitSelection";
import * as LABELS from "./labels";
import {
    changeIngredientPosition,
    changeIngredientProperty,
    INGREDIENT_PROP_AMOUNT,
    INGREDIENT_PROP_DELETE,
    INGREDIENT_PROP_NAME,
    INGREDIENT_PROP_UNIT
} from "./actions";
import {unitHasNoAmount} from "../common/enumHelpers";
import {ARROW_DOWN, ARROW_UP, getNavigationButton} from "./commonNavigation";
import {Dispatch} from "redux";

const isNumber = (input: any) => input !== "" && !isNaN(input);

interface IIngredientProps {
    amount: number;
    canMoveIngredients: boolean;
    onChangeAmount: (amount: string) => void;
    onChangeName: (name: string) => void;
    onChangePosition: (position: string) => void;
    onChangeUnit: (unit: string) => void;
    onDelete: () => void;
    name: string;
    showDownArrow: boolean;
    showUpArrow: boolean;
    showError: () => boolean;
    unit: string;
}

const Ingredient = ({
                        amount,
                        canMoveIngredients,
                        onChangeAmount,
                        onChangeName,
                        onChangePosition,
                        onChangeUnit,
                        onDelete,
                        name,
                        showDownArrow,
                        showUpArrow,
                        showError,
                        unit
                    }: IIngredientProps) => {
    let navigationButtons = null;
    if (canMoveIngredients === true) {
        navigationButtons = (
            <td>
                <div style={{display: "block"}}>
                    <div>
                        {getNavigationButton(
                            showUpArrow,
                            ARROW_UP,
                            onChangePosition,
                            faArrowUp
                        )}
                    </div>
                    <div>
                        {getNavigationButton(
                            showDownArrow,
                            ARROW_DOWN,
                            onChangePosition,
                            faArrowDown
                        )}
                    </div>
                </div>
            </td>
        );
    }

    let amountInput;
    if (unitHasNoAmount(unit)) {
        amountInput = null;
    } else {
        amountInput = (
            <input
                className={`ingredientAmountInput ${
                    showError() && !isNumber(amount) ? "inputError" : "inputOK"
                }`}
                onChange={event => onChangeAmount(event.target.value)}
                placeholder={LABELS.PLACEHOLDER_INGREDIENT_AMOUNT}
                value={amount}
            />
        );
    }

    return (
        <tr>
            <td style={{paddingRight: "10px"}}>{amountInput}</td>
            <td style={{paddingRight: "10px"}}>
                <UnitSelection value={unit} onChange={onChangeUnit}/>
            </td>
            <td>
                <input
                    className={`ingredientNameInput ${
                        showError() && name === "" ? "inputError" : "inputOK"
                    }`}
                    placeholder={LABELS.PLACEHOLDER_INGREDIENT_NAME}
                    onChange={event => onChangeName(event.target.value)}
                    value={name}
                />
            </td>
            {navigationButtons}
            <td>
                <button type="button" className="iconButton" onClick={() => onDelete()}>
                    <FontAwesomeIcon icon={faTrashAlt}/>
                </button>
            </td>
        </tr>
    );
};

const changeCommaToPeriod = (input: string) => {
    const newValue = input;
    return newValue.replace(/,/g, ".");
};

const getIngredientFromState = (state: any, props: any) => {
    const groupUuid = props.groupUuid;
    const ingredientUuid = props.ingredientUuid;
    const ingredientGroups = state.changeRecipeState.ingredientGroups;
    return ingredientGroups.get(groupUuid).ingredients.get(ingredientUuid);
};

const mapStateToProps = (state: any, props: any) => ({
    showError: () =>
        state.changeRecipeState.stateStatus === LABELS.RECIPE_SAVE_FAILED,
    name: getIngredientFromState(state, props).name,
    amount: getIngredientFromState(state, props).amount,
    unit: getIngredientFromState(state, props).unit
});

const mapDispatchToProps = (dispatch: Dispatch, props: any) => {
    const groupUuid = props.groupUuid;
    const ingredientUuid = props.ingredientUuid;

    return {
        onDelete: () =>
            dispatch(
                changeIngredientProperty(
                    groupUuid,
                    ingredientUuid,
                    INGREDIENT_PROP_DELETE
                )
            ),
        onChangeName: (name: string) =>
            dispatch(
                changeIngredientProperty(
                    groupUuid,
                    ingredientUuid,
                    INGREDIENT_PROP_NAME,
                    name
                )
            ),
        onChangeUnit: (unit: string) =>
            dispatch(
                changeIngredientProperty(
                    groupUuid,
                    ingredientUuid,
                    INGREDIENT_PROP_UNIT,
                    unit
                )
            ),
        onChangeAmount: (amount: string) =>
            dispatch(
                changeIngredientProperty(
                    groupUuid,
                    ingredientUuid,
                    INGREDIENT_PROP_AMOUNT,
                    changeCommaToPeriod(amount)
                )
            ),
        onChangePosition: (position: string) =>
            dispatch(changeIngredientPosition(groupUuid, ingredientUuid, position))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Ingredient);
