import {
    ADD_RECIPE_GROUP,
    CHANGE_GROUP_POSITION,
    CHANGE_INGREDIENT_POSITION,
    CHANGE_INGREDIENT_PROPERTY,
    CHANGE_RECIPE_CATEGORY,
    CHANGE_RECIPE_GROUP_NAME,
    CHANGE_RECIPE_INSTRUCTIONS,
    CHANGE_RECIPE_NAME,
    CLEAR_RECIPE,
    DELETE_RECIPE_GROUP,
    INGREDIENT_PROP_AMOUNT,
    INGREDIENT_PROP_DELETE,
    INGREDIENT_PROP_NAME,
    INGREDIENT_PROP_NEW,
    INGREDIENT_PROP_UNIT,
    RECIPE_API_RESULT,
    RECIPE_LOADED
} from "./actions";

import {unitHasNoAmount} from "../common/enumHelpers";

import {RECIPE_CHANGES_PENDING} from "./labels";

import uuidv1 from "uuid";
import {ICategory} from "../searchRecipe/recipeCategory";

const RecipeApi = require("../api/recipeApi");

const defaultState = () => {
    const newUuid = uuidv1();
    const ingredientGroupMap = new Map();
    ingredientGroupMap.set(newUuid, generateNewIngredientGroup(newUuid));

    return {
        categories: [],
        id: "",
        ingredientGroups: ingredientGroupMap,
        instructions: "",
        name: "",
        stateStatus: ""
    };
};

export const changeRecipeState = (state: any, action: any) => {
    if (state === undefined) {
        return defaultState();
    }

    switch (action.type) {
        case ADD_RECIPE_GROUP:
            return Object.assign({}, state, {
                ingredientGroups: addNewIngredientGroup(state),
                stateStatus: RECIPE_CHANGES_PENDING
            });

        case CHANGE_GROUP_POSITION:
            return Object.assign({}, state, {
                ingredientGroups: changeGroupPosition(state, action),
                stateStatus: RECIPE_CHANGES_PENDING
            });

        case CHANGE_INGREDIENT_POSITION:
            return Object.assign({}, state, {
                ingredientGroups: changeIngredientPosition(state, action),
                stateStatus: RECIPE_CHANGES_PENDING
            });

        case CHANGE_INGREDIENT_PROPERTY:
            return Object.assign({}, state, {
                ingredientGroups: changeIngredientProperty(state, action),
                stateStatus: RECIPE_CHANGES_PENDING
            });

        case CHANGE_RECIPE_CATEGORY:
            return Object.assign({}, state, {
                categories: changeRecipeCategories(state, action.category),
                stateStatus: RECIPE_CHANGES_PENDING
            });

        case CHANGE_RECIPE_GROUP_NAME:
            return Object.assign({}, state, {
                ingredientGroups: changeRecipeGroupName(
                    state,
                    action.uuid,
                    action.name
                ),
                stateStatus: RECIPE_CHANGES_PENDING
            });

        case CHANGE_RECIPE_INSTRUCTIONS:
            return Object.assign({}, state, {
                instructions: action.instructions,
                stateStatus: RECIPE_CHANGES_PENDING
            });

        case CHANGE_RECIPE_NAME:
            return Object.assign({}, state, {
                name: action.name,
                stateStatus: RECIPE_CHANGES_PENDING
            });

        case CLEAR_RECIPE:
            return defaultState();

        case DELETE_RECIPE_GROUP:
            return Object.assign({}, state, {
                ingredientGroups: removeIngredientGroup(state, action.uuid),
                stateStatus: RECIPE_CHANGES_PENDING
            });

        case RECIPE_API_RESULT:
            return Object.assign({}, state, {stateStatus: action.apiResult});

        case RECIPE_LOADED:
            const newState = RecipeApi.mapFromBackendToReduxState(
                action.loadedRecipe
            );
            return Object.assign({}, state, {
                categories: newState.categories,
                id: newState.id,
                ingredientGroups: newState.ingredientGroups,
                instructions: newState.instructions,
                name: newState.name
            });

        default:
            return state;
    }
};

const changeRecipeCategories = (state: any, updatedCategory: ICategory) => {
    let newCategoryList = state.categories.slice();
    if (newCategoryList.includes(updatedCategory)) {
        newCategoryList = newCategoryList.filter(
            (category: ICategory) => category !== updatedCategory
        );
    } else {
        newCategoryList.push(updatedCategory);
    }

    return newCategoryList;
};

const changeRecipeGroupName = (state: any, uuid: string, name: string) => {
    const newMap: any = new Map(state.ingredientGroups);
    newMap.get(uuid).name = name;
    return newMap;
};

const addNewIngredientGroup = (state: any) => {
    const newMap = new Map(state.ingredientGroups);
    const newUuid = uuidv1();
    newMap.set(newUuid, generateNewIngredientGroup(newUuid));
    return newMap;
};

const changeGroupPosition = (state: any, action: any) => {
    let groupIdArray = Array.from(state.ingredientGroups.keys());
    const theIndex = groupIdArray.indexOf(action.group);

    if (action.position === "up") {
        groupIdArray = swapElements(theIndex, theIndex - 1, groupIdArray);
    } else if (action.position === "down") {
        groupIdArray = swapElements(theIndex, theIndex + 1, groupIdArray);
    }

    const newGroupMap = new Map();
    groupIdArray.forEach(id => {
        newGroupMap.set(id, state.ingredientGroups.get(id));
    });

    return newGroupMap;
};

const changeIngredientPosition = (state: any, action: any) => {
    let ingredientIdArray = Array.from(
        state.ingredientGroups.get(action.group).ingredients.keys()
    );

    const theIndex = ingredientIdArray.indexOf(action.ingredient);
    if (action.position === "up") {
        ingredientIdArray = swapElements(theIndex, theIndex - 1, ingredientIdArray);
    } else if (action.position === "down") {
        ingredientIdArray = swapElements(theIndex, theIndex + 1, ingredientIdArray);
    }

    const newIngredientMap = new Map();
    ingredientIdArray.forEach(id => {
        newIngredientMap.set(
            id,
            state.ingredientGroups.get(action.group).ingredients.get(id)
        );
    });

    const newGroupMap: any = new Map(state.ingredientGroups);
    newGroupMap.get(action.group).ingredients = newIngredientMap;
    return newGroupMap;
};

const removeIngredientGroup = (state: any, uuid: any) => {
    const newMap = new Map(state.ingredientGroups);
    newMap.delete(uuid);
    return newMap;
};

const changeIngredientProperty = (state: any, action: any) => {
    const newGroupMap: any = new Map(state.ingredientGroups);
    const newIngredientMap: any = new Map(newGroupMap.get(action.group).ingredients);

    switch (action.property) {
        case INGREDIENT_PROP_UNIT:
            if (unitHasNoAmount(action.value)) {
                newIngredientMap.get(action.ingredient).amount = "0";
            }
            newIngredientMap.get(action.ingredient).unit = action.value;
            break;

        case INGREDIENT_PROP_AMOUNT:
            newIngredientMap.get(action.ingredient).amount = action.value;
            break;

        case INGREDIENT_PROP_NAME:
            newIngredientMap.get(action.ingredient).name = action.value;
            break;

        case INGREDIENT_PROP_DELETE:
            newIngredientMap.delete(action.ingredient);
            break;

        case INGREDIENT_PROP_NEW:
            const newIngredient = generateNewIngredient();
            newIngredientMap.set(newIngredient.uuid, newIngredient);
            break;

        default:
            break;
    }

    newGroupMap.get(action.group).ingredients = newIngredientMap;
    return newGroupMap;
};

const generateNewIngredient = () => ({
    name: "",
    unit: "",
    amount: "",
    uuid: uuidv1()
});

const generateNewIngredientGroup = (uuid: string) => {
    const newIngredient1 = {
        name: "",
        unit: "",
        amount: "",
        uuid: uuidv1()
    };
    const newIngredient2 = {
        name: "",
        unit: "",
        amount: "",
        uuid: uuidv1()
    };

    const ingredientsMap = new Map();
    ingredientsMap.set(newIngredient1.uuid, newIngredient1);
    ingredientsMap.set(newIngredient2.uuid, newIngredient2);

    return {
        name: "",
        uuid,
        ingredients: ingredientsMap
    };
};

const swapElements = (a: number, b: number, array: any[]) => {
    if (a < 0 || b < 0 || a > array.length - 1 || b > array.length - 1) {
        return array;
    }

    const tmp = array[a];
    array[a] = array[b];
    array[b] = tmp;
    return array;
};
