import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

export const ARROW_UP = "up";
export const ARROW_DOWN = "down";

export const getNavigationButton = (
    showButton: boolean,
    position: string,
    onChangePosition: (position: string) => void,
    icon: IconProp
) => {
    if (showButton) {
        return (
            <button
                type="button"
                className="arrowButton"
                onClick={() => onChangePosition(position)}
            >
                <FontAwesomeIcon icon={icon}/>
            </button>
        );
    }
    return null;
};

export const showNavigationArrows = (totalNumberOfElements: number, index: number) => {
    let showUpArrow = false;
    let showDownArrow = false;

    if (totalNumberOfElements > 1) {
        if (index >= 0 && index < totalNumberOfElements - 1) {
            showDownArrow = true;
        }
        if (index > 0 && index < totalNumberOfElements) {
            showUpArrow = true;
        }
    }

    return {up: showUpArrow, down: showDownArrow};
};
