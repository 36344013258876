import React, {Component} from "react";
import ReactModal from 'react-modal';
import {BUTTONSTYLE_CLOSE_WINDOW, StyledButton} from "./styledButton";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import {faCheck} from "@fortawesome/free-solid-svg-icons/faCheck";

class ConfirmationModal extends Component<IConfirmationModalProps, IConfirmationModalState> {

    constructor(props: IConfirmationModalProps) {
        super(props);
        this.state = {
            showModal: false
        };

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    handleOpenModal() {
        this.setState({showModal: true});
    }

    handleCloseModal() {
        this.setState({showModal: false});
    }

    render() {
        return (
            <div>
                <div>
                    <StyledButton
                        icon={this.props.buttonIcon}
                        text={this.props.buttonText}
                        onClickCB={this.handleOpenModal}
                    />
                </div>

                <ReactModal
                    closeTimeoutMS={300}
                    isOpen={this.state.showModal}
                    contentLabel="onRequestClose"
                    onRequestClose={this.handleCloseModal}
                    className="ModalContent"
                    overlayClassName="ModalOverlay"
                >

                    <div style={{textAlign: "center"}}>
                        <h1>{this.props.modalHeader}</h1>
                        <p>
                            {this.props.modalText}
                        </p>
                        <div>
                            <StyledButton
                                icon={faCheck}
                                onClickCB={() => {
                                    this.handleCloseModal();
                                    this.props.onAccept();
                                }
                                }
                                text={this.props.acceptButtonText}
                            />
                            <StyledButton
                                buttonStyle={BUTTONSTYLE_CLOSE_WINDOW}
                                onClickCB={this.handleCloseModal}
                            />
                        </div>
                    </div>
                </ReactModal>
            </div>
        );
    }
}

interface IConfirmationModalProps {
    onAccept: () => void;
    buttonIcon: IconDefinition;
    buttonText: string;
    modalHeader: string;
    modalText: string;
    acceptButtonText: string;
}

interface IConfirmationModalState {
    showModal: boolean;
}

export default ConfirmationModal;