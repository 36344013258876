import {IShoppingListCollectionDto} from "../shoppingList/types";
import {BackendCallStatusEnum, DataActionEnum} from "../types";

export const UPDATE_SHOPPING_LISTS = "UPDATE_SHOPPING_LISTS";
export const updateShoppingLists = (shoppinglists: IShoppingListCollectionDto) => ({
    type: UPDATE_SHOPPING_LISTS,
    shoppinglists
});

export const OPEN_SHARE_SHOPPIG_LIST_MODAL = "OPEN_SHARE_SHOPPIG_LIST_MODAL";
export const openShareShoppingListModal = (shoppingListId: string) => ({
    type: OPEN_SHARE_SHOPPIG_LIST_MODAL,
    shoppingListId
});

export const CLOSE_SHARE_SHOPPING_LIST = "CLOSE_SHARE_SHOPPING_LIST";
export const closeShareShoppingList = () => ({
    type: CLOSE_SHARE_SHOPPING_LIST
});


/* --------------------------------- */
export const TOGGLE_DELETE_MODE = "TOGGLE_DELETE_MODE";
export const toggleDeleteModeAction = () => ({
    type: TOGGLE_DELETE_MODE
});

export const TOGGLE_SHARE_MODE = "TOGGLE_SHARE_MODE";
export const toggleShareModeAction = () => ({
    type: TOGGLE_SHARE_MODE
});

export const REFRESH_SHOPPING_LISTS = "REFRESH_SHOPPING_LISTS";
export const refreshShoppingLists = (dataAction: DataActionEnum, shoppingLists: IShoppingListCollectionDto | null) => (
    {
        type: REFRESH_SHOPPING_LISTS,
        dataAction: dataAction,
        shoppingLists: shoppingLists
    }
);

export const UPDATE_SHOPPINGLIST_MENU_BACKEND_STATUS = "UPDATE_SHOPPINGLIST_MENU_BACKEND_STATUS";
export const updateShoppinglistMenuBackendStatus = (status: BackendCallStatusEnum, dataAction: DataActionEnum) => ({
    type: UPDATE_SHOPPINGLIST_MENU_BACKEND_STATUS,
    status: status,
    dataAction: dataAction
});