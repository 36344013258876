import React from "react";
import ReactDOM from "react-dom";
import { createStore, combineReducers } from "redux";
import { Provider } from "react-redux";
import "./index.css";
import { changeRecipeState } from "./changeRecipe/reducer";
import { searchRecipeState } from "./searchRecipe/reducer";
import { shoppingListState } from "./shoppingList/reducer";
import { shoppingListMenuState } from "./shoppingListMenu/reducer";
import { accountState } from "./account/reducer";
import { commonState } from "./reducer";
import Main from "./main";

ReactDOM.render(
  <Provider
    store={createStore(
      combineReducers({
        accountState,
        commonState,
        changeRecipeState,
        searchRecipeState,
        shoppingListMenuState,
        shoppingListState
      })
    )}
  >
    <Main />
  </Provider>,
  document.getElementById("root")
);
