import React from "react";
import {ScaleLoader} from "react-spinners";

export const LoaderAnimation = () => (
    <div style={{padding: "20px"}}>
        <div style={{display: "inline-block", verticalAlign: "middle"}}>
            <ScaleLoader color="#000" loading/>
        </div>
        <div style={{display: "inline-block", verticalAlign: "middle"}}>
            <p style={{paddingLeft: "10px"}}>Henter data...</p>
        </div>
    </div>
);


export const LoaderWithText = (displayText: String): JSX.Element => {
    return (
        <div style={{textAlign: "center", display: "flex", margin: "0 auto", flexDirection: "column"}}>
            <div style={{margin: "0 auto", paddingTop: "1em"}}>
                <ScaleLoader height={100} width={5} radius={100} margin={"3px"} color={"DarkSlateGray"}/>
            </div>
            <div style={{paddingTop: "1.5em"}}>
                {displayText}
            </div>
        </div>
    );
};