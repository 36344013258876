import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {GetIconDisplay} from "./utils";
import {IShoppingListDto} from "../shoppingList/types";
import {MenuDisplayEnum} from "../types";

const showShoppingList = (id: string, history: any) => {
    history.push(`/handlelister/${id}`);
};

interface ISharedTileProps {
    location: any;
    history: any;
    match: any;
    shoppinglist: IShoppingListDto;
}

class SharedTile extends Component<ISharedTileProps, any> {
    render() {
        const className = "MenuTile ShareTile";
        const onClick = () => showShoppingList(this.props.shoppinglist.id, this.props.history);

        return (
            <div className={className} onClick={onClick}>
                <h2>{GetIconDisplay(MenuDisplayEnum.NORMAL_MODE, this.props.shoppinglist)}</h2>
                <h2>{this.props.shoppinglist.name}</h2>
            </div>
        );
    }
}

export default withRouter(SharedTile);
