import {connect} from "react-redux";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowCircleDown, faArrowCircleUp, faPlus} from "@fortawesome/free-solid-svg-icons";
import {faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import {
    changeGroupPosition,
    changeIngredientProperty,
    changeRecipeGroupName,
    deleteRecipeGroup,
    INGREDIENT_PROP_NEW
} from "./actions";
import Ingredient from "./ingredient";
import * as LABELS from "./labels";

import {ARROW_DOWN, ARROW_UP, getNavigationButton, showNavigationArrows} from "./commonNavigation";
import {tIngredientsMap} from "../showRecipe/types";
import {Dispatch} from "redux";

interface IIngredientListProps {
    addNewIngredient: () => void;
    canMoveIngredients: boolean;
    changeGroupName: (name: string) => void;
    changeGroupPositionProp: (position: string) => void;
    deleteGroup: () => void;
    groupUuid: string;
    ingredients: tIngredientsMap;
    name: string;
    showDownArrow: boolean;
    showUpArrow: boolean;
    showError: () => boolean;
}

const IngredientList = ({
                            addNewIngredient,
                            canMoveIngredients,
                            changeGroupName,
                            changeGroupPositionProp,
                            deleteGroup,
                            groupUuid,
                            ingredients,
                            name,
                            showDownArrow,
                            showUpArrow,
                            showError
                        }: IIngredientListProps) => {
    const ingredientList = Array.from(ingredients.keys()).map(
        (ingredientUuid, index) => {
            const navigationArrowsVisibility = showNavigationArrows(
                ingredients.size,
                index
            );

            return (
                <Ingredient
                    canMoveIngredients={canMoveIngredients}
                    groupUuid={groupUuid}
                    ingredientUuid={ingredientUuid}
                    key={ingredientUuid}
                    showUpArrow={navigationArrowsVisibility.up}
                    showDownArrow={navigationArrowsVisibility.down}
                />
            );
        }
    );

    let navigationButtons = null;
    if (canMoveIngredients) {
        navigationButtons = (
            <td>
                <div style={{display: "block"}}>
                    <div>
                        {getNavigationButton(
                            showUpArrow,
                            ARROW_UP,
                            changeGroupPositionProp,
                            faArrowCircleUp
                        )}
                    </div>
                    <div>
                        {getNavigationButton(
                            showDownArrow,
                            ARROW_DOWN,
                            changeGroupPositionProp,
                            faArrowCircleDown
                        )}
                    </div>
                </div>
            </td>
        );
    }

    return (
        <table>
            <tbody>
            <tr>
                <td colSpan={3} className="groupHeader">
                    <input
                        className={`ingredientGroupNameInput ${
                            showError() && name === "" ? "inputError" : "inputOK"
                        }`}
                        placeholder={LABELS.PLACEHOLDER_INGREDIENT_GROUP_NAME}
                        value={name}
                        onChange={event => changeGroupName(event.target.value)}
                    />
                </td>
                {navigationButtons}
                <td>
                    <button
                        type="button"
                        className="iconButton"
                        onClick={() => addNewIngredient()}
                    >
                        <FontAwesomeIcon icon={faPlus}/>
                    </button>
                </td>
                <td>
                    <button
                        type="button"
                        className="iconButton"
                        onClick={() => deleteGroup()}
                    >
                        <FontAwesomeIcon icon={faTrashAlt}/>
                    </button>
                </td>
            </tr>
            {ingredientList}
            </tbody>
        </table>
    );
};

const mapStateToProps = (state: any, ownProps: any) => {
    const ingredientGroup = state.changeRecipeState.ingredientGroups.get(
        ownProps.groupUuid
    );

    return {
        name: ingredientGroup.name,
        ingredients: ingredientGroup.ingredients,
        showError: () =>
            state.changeRecipeState.stateStatus === LABELS.RECIPE_SAVE_FAILED
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: any) => ({
    addNewIngredient: () =>
        dispatch(
            changeIngredientProperty(props.groupUuid, null, INGREDIENT_PROP_NEW)
        ),
    changeGroupName: (name: string) =>
        dispatch(changeRecipeGroupName(props.groupUuid, name)),
    changeGroupPositionProp: (position: string) =>
        dispatch(changeGroupPosition(props.groupUuid, position)),
    deleteGroup: () => dispatch(deleteRecipeGroup(props.groupUuid))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IngredientList);
