import React from "react";

import oscar_questionmark from "./media/oscar_questionmark.png";

export const show404 = () => (
    <div style={{textAlign: "center"}}>
        <h1>404 - oiiii!</h1>
        <h2>
            Her har det skjedd noe feil! Det var ikke meningen at du skulle havne her.
        </h2>
        <img src={oscar_questionmark} height="300" alt="oscar_questionmark"/>
    </div>
);
