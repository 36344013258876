import { resolveAccessToken } from "./accessToken";
import { getBackendURI } from "../backendconfig";
import { handleHttpStatus } from "./statusHandler";

const mapIngredients = ingredients => {
  const map = new Map();
  ingredients.forEach(ingredient => {
    map.set(ingredient.uuid, {
      amount: ingredient.amount,
      name: ingredient.name,
      unit: ingredient.unit,
      uuid: ingredient.uuid
    });
  });

  return map;
};

const mapIngredientGroup = group => ({
  name: group.name,
  uuid: group.uuid,
  ingredients: mapIngredients(group.ingredients)
});

export const mapFromBackendToReduxState = response => {
  const ingredientGroups = new Map();
  response.ingredientGroups.forEach(group => {
    ingredientGroups.set(group.uuid, mapIngredientGroup(group));
  });

  return {
    categories: response.categories,
    id: response.id,
    ingredientGroups,
    instructions: response.instructions,
    name: response.name,
    stateStatus: ""
  };
};

export const mapFromReduxStateToBackendRequest = state => {
  const groupArray = [];
  Array.from(state.ingredientGroups.values()).forEach(group => {
    groupArray.push({
      ingredients: Array.from(group.ingredients.values()),
      name: group.name,
      uuid: group.uuid
    });
  });

  return {
    categories: state.categories,
    id: state.id,
    ingredientGroups: groupArray,
    instructions: state.instructions,
    name: state.name
  };
};

export const getAllRecipes = () =>
  resolveAccessToken()
    .then(accessToken =>
      fetch(`${getBackendURI()}/api/recipe/`, {
        method: "GET",
        headers: {
          Authorization: accessToken
        }
      })
    )
    .then(result => handleHttpStatus(result));

export const saveRecipe = requestObject =>
  resolveAccessToken().then(accessToken =>
    fetch(`${getBackendURI()}/api/recipe/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: accessToken
      },
      body: JSON.stringify(mapFromReduxStateToBackendRequest(requestObject))
    })
  );

export const getRecipeById = recipeId =>
  resolveAccessToken()
    .then(accessToken =>
      fetch(`${getBackendURI()}/api/recipe/${recipeId}`, {
        method: "GET",
        headers: {
          Authorization: accessToken
        }
      })
    )
    .then(result => handleHttpStatus(result));

export const deleteRecipe = id =>
  resolveAccessToken().then(accessToken =>
    fetch(`${getBackendURI()}/api/recipe/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: accessToken
      }
    })
  );